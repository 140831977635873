export const system = {
  white: "#ffffff",
  gray100: "#f6f9fc",
  gray200: "#e9ecef",
  gray300: "#e3e3e3",
  gray400: "#ced4da",
  gray500: "#adb5bd",
  gray600: "#6c757d", // Line footer color
  gray700: "#525f7f", // Line p color
  gray800: "#32325d", // Line heading color
  gray900: "#212529",
  gray: "#6c757d",
  light: "#ced4da",
  lighter: "#e9ecef",
  darkGray: "#32325d",
  blue: "#5e72e4",
  indigo: "#5603ad",
  purple: "#8965e0",
  pink: "#f3a4b5",
  red: "#f5365c",
  orange: "#fb6340",
  yellow: "#ffd600",
  green: "#2dce89",
  teal: "#11cdef",
  cyan: "#2bffc6",
  default: "#344675",
  primary: "#fa2697",
  secondary: "#f4f5f7",
  success: "#00f2c3",
  info: "#1d8cf8",
  warning: "#ff8d72",
  danger: "#fd5d93",
  black: "#222a42",
};

export const primary = "#fa2697";
export const defaultColor = "#344675";

export interface SystemColors {
  [key: string]: any;
  dark: { [key: string]: any };
  light: { [key: string]: any };
}

const systemColors: SystemColors = {
  dark: {
    primary,
    default: system.white,
    text: "rgba(255, 255, 255, 0.7)",
    buttonBorder: system.lighter,
    spotBorder: system.primary,
    spotBorderHover: system.white,
  },
  light: {
    primary,
    default: system.default,
    text: "rgba(34, 42, 66, 0.7)",
    buttonBorder: system.light,
    spotBorder: system.primary,
    spotBorderHover: system.gray100,
  },
};

export default systemColors;
