import { createSelector, PayloadAction } from "@reduxjs/toolkit";
import { Display, DisplayBase } from "validation/schemas/display";
import { apiCallBegan, ApiCallBeganActionPayload } from "./apiActions";

import {
  createEntitySlice,
  loadEntities,
  saveEntity,
  openEntity,
  closeEntity,
  deleteEntity,
  EntityState,
  LoadEntitiesParameters,
  SaveEntityParameters,
  syncEntity,
  //   getOnSuccess,
} from "./entity";

export const idAttribute = "display_id_k";

export const COMMAND_REBOOT = 1;
export const COMMAND_SCREEN_OFF = 2;
export const COMMAND_SCREEN_ON = 3;

const getDefaultTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    return "";
  }
};

export const defaultDisplay: DisplayBase = {
  os: "android",
  orientation: "landscape",
  usage: "default",
  groups: [],
  timezone: getDefaultTimezone(),
};

const baseUrl = "/displays";
const activateUrl = "/displays/code";
const name = "displays";
const nameAttribute = "display_name";

const initialState: EntityState<Display> = {
  synchronizing: false,
  list: [],
  idAttribute,
  nameAttribute,
  loading: false,
  data: {},
};

// Create Slice
const slice = createEntitySlice({
  name,
  initialState,
  reducers: {
    statusOpened: (
      state: EntityState<Display>,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.current = action.payload as Display;
      state.data.status = undefined;
      state.data.checkStatus = true;
    },
    statusChecked: (
      state: EntityState<Display>,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.data.status = action.payload;
      state.synchronizing = false;
      state.error = undefined;
      //   state.current = undefined;
      state.progress = undefined;
    },
    statusClosed: (state: EntityState<Display>) => {
      state.current = undefined;
      state.data.status = undefined;
      state.data.checkStatus = false;
    },
  },
});

// Export Reducer and Actions
export default slice.reducer;
export const { actions } = slice;

// Action Creators
export const getDisplayStatus = (id: string) => {
  const requestUrl = encodeURI(`${baseUrl}/status/${id}`);
  const method = "get";

  const onStart = slice.actions.requested.type;
  const onSuccess = slice.actions.statusChecked.type;
  const onError = slice.actions.requestFailed.type;

  return apiCallBegan({
    url: requestUrl,
    method,
    onStart,
    onSuccess,
    onError,
  });
};

export const monitorDisplay = (data: Display) => {
  //   console.log("monitorDisplay", { data });
  const { display_id_k, status } = data;
  if (status !== "creating") return;

  return syncEntity({
    url: baseUrl,
    id: display_id_k,
    slice,
    monitors: [monitorDisplay],
  });
};

export const loadDisplays = (params?: LoadEntitiesParameters["params"]) =>
  loadEntities({ url: baseUrl, params, slice });

export const activateDisplay = (data: SaveEntityParameters["data"]) =>
  saveEntity({
    url: activateUrl,
    idAttribute,
    data,
    slice,
    monitors: [monitorDisplay],
  });

export const saveDisplay = (data: SaveEntityParameters["data"]) =>
  saveEntity({
    url: baseUrl,
    idAttribute,
    data,
    slice,
    monitors: [monitorDisplay],
  });

export const deleteDisplay = (id: string) =>
  deleteEntity({ url: baseUrl, id, slice });

export const newDisplay = (data = { ...defaultDisplay }) =>
  openEntity({ data, slice });

export const openDisplay = (data: { [key: string]: any }) =>
  openEntity({ data, slice });

export const openDisplayStatus = (data: Record<string, any>) =>
  slice.actions.statusOpened(data);

export const closeDisplayStatus = () => slice.actions.statusClosed();

export const closeDisplay = () => closeEntity({ slice });

export interface SendCommandParameters
  extends Omit<
    Omit<Omit<ApiCallBeganActionPayload, "idAttribute">, "data">,
    "url"
  > {
  id: string;
  command: number;
}

export interface CheckLicenseParameters
  extends Omit<
    Omit<Omit<ApiCallBeganActionPayload, "idAttribute">, "data">,
    "url"
  > {
  id: string;
}

export interface DeactivateParameters
  extends Omit<
    Omit<Omit<ApiCallBeganActionPayload, "idAttribute">, "data">,
    "url"
  > {
  id: string;
  data: Record<string, any>;
}

export interface ReactivateParameters
  extends Omit<
    Omit<Omit<ApiCallBeganActionPayload, "idAttribute">, "data">,
    "url"
  > {
  id: string;
}

export const checkLicense = ({
  id,
  onStart = slice.actions.requested.type,
  onSuccess = slice.actions.requestSucceeded.type,
  onError = slice.actions.requestFailed.type,
}: CheckLicenseParameters) => {
  const requestUrl = encodeURI(`${baseUrl}/${id}/check-license`);
  const method = "post";

  //   console.log("sending command", JSON.stringify({ requestUrl, data }));

  return apiCallBegan({
    url: requestUrl,
    method,
    onStart,
    onSuccess,
    onError,
  });
};

export const deactivate = ({
  id,
  data,
  onStart = slice.actions.requested.type,
  onSuccess = slice.actions.updated.type,
  onError = slice.actions.requestFailed.type,
}: DeactivateParameters) => {
  const requestUrl = encodeURI(`${baseUrl}/${id}/deactivate`);
  const method = "post";

  //   console.log("deactivate", JSON.stringify({ requestUrl, data }));

  return apiCallBegan({
    url: requestUrl,
    data,
    method,
    onStart,
    onSuccess,
    onError,
  });
};

export const reactivate = ({
  id,
  onStart = slice.actions.requested.type,
  onSuccess = slice.actions.updated.type,
  onError = slice.actions.requestFailed.type,
}: ReactivateParameters) => {
  const requestUrl = encodeURI(`${baseUrl}/${id}/reactivate`);
  const method = "post";

  //   console.log("deactivate", JSON.stringify({ requestUrl, data }));

  return apiCallBegan({
    url: requestUrl,
    method,
    onStart,
    onSuccess,
    onError,
  });
};

export const sendCommand = ({
  id,
  command,
  onStart = slice.actions.requested.type,
  onSuccess = slice.actions.requestSucceeded.type,
  onError = slice.actions.requestFailed.type,
}: SendCommandParameters) => {
  const requestUrl = encodeURI(`${baseUrl}/${id}/command`);
  const method = "post";
  const data = { command };

  //   console.log("sending command", JSON.stringify({ requestUrl, data }));

  return apiCallBegan({
    url: requestUrl,
    method,
    data,
    onStart,
    onSuccess,
    onError,
  });
};

export const creators = {
  loadDisplays,
  saveDisplay,
  deleteDisplay,
  newDisplay,
  openDisplay,
  closeDisplay,
  activateDisplay,
};

export const selectDisplays = createSelector(
  (state: { [key: string]: any }) => state.entities.displays,
  (displays) => displays
);

export const selectDisplaysOptions = createSelector(
  (state: { [key: string]: any }) => state.entities.displays,
  ({ list }) =>
    list.map((display: { [key: string]: string }) => ({
      value: display[idAttribute],
      label: display[nameAttribute],
    }))
);

export const selectDisplayStatus = createSelector(
  (state: { [key: string]: any }) => state.entities.displays,
  (displays) => displays.data.status
);
