/* eslint jsx-a11y/click-events-have-key-events: 0 */
import React from "react";
import uploadImage from "assets/img/media_file_placeholder.png";

import { Button, FormFeedback, FormGroup, Label } from "reactstrap";

export interface AppMediaFileInputProps {
  disabled?: boolean;
  accept?: string;
  label?: string;
  multiple?: boolean;
  onFileAdded?: (file: File | null | undefined) => void;
  error?: string;
  btnText?: string;
  btnChangeText?: string;
}

export interface AppMediaFileInputState {
  hightlight: boolean;
  file?: File;
  type: "video" | "image";
  mediaSource?: string;
}

const initialState: AppMediaFileInputState = {
  hightlight: false,
  mediaSource: uploadImage,
  type: "image",
};

// const AppMediaFileInput: React.FC<AppMediaFileInputProps> = ({ disabled = false, multiple = false, accept = '*' }) => {
const AppMediaFileInput: React.FC<AppMediaFileInputProps> = ({
  accept = "*",
  multiple = false,
  label,
  onFileAdded,
  error,
  disabled = false,
  btnChangeText,
  btnText,
}) => {
  const [state, setState] = React.useState(initialState);
  const { mediaSource, file, type } = state;
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const openFileDialog = () => {
    if (disabled) return;
    // console.log({ fileInputRef });
    if (fileInputRef.current) fileInputRef.current.click();
  };

  const handleFileAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (disabled) return;

    // console.log("handleFileAdded", { event });

    if (event.target && event.target.files) {
      const addedFile = event.target.files[0];
      // console.log('handleFileAdded file added', file);

      const reader = new FileReader();
      reader.onloadend = () => {
        const addedType = addedFile.type.match(/image.*/)
          ? "image"
          : addedFile.type.match(/video.*/)
          ? "video"
          : "image";

        // console.log("onloadend", {
        //   addedFile,
        //   addedType,
        //   result: reader.result,
        // });

        setState((s) => ({
          ...s,
          file: addedFile,
          type: addedType,
          mediaSource: reader.result as string,
        }));
        if (onFileAdded) onFileAdded(addedFile);

        if (addedType === "video") videoRef?.current?.load();

        // setState({
        //   file: file,
        //   imagePreviewUrl: reader.result
        // });
      };

      //   console.log("handleFileAdded", { addedFile });

      reader.readAsDataURL(addedFile);

      // setState({ ...state, file: addedFile });
      // if (onFileAdded) onFileAdded(addedFile);
    }
  };

  const handleFileDropped = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (disabled) return;
    // console.log('handleFileDropped', event);

    if (event.dataTransfer && event.dataTransfer.files) {
      const addedFile = event.dataTransfer.files[0];
      // console.log('handleFileDropped file added', file);

      const reader = new FileReader();
      reader.onloadend = () => {
        // console.log({ file: addedFile, result: reader.result });

        const addedType = addedFile.type.match(/image.*/)
          ? "image"
          : addedFile.type.match(/video.*/)
          ? "video"
          : "image";

        setState((s) => ({
          ...s,
          file: addedFile,
          type: addedType,
          mediaSource: reader.result as string,
          hightlight: false,
        }));

        if (onFileAdded) onFileAdded(addedFile);
        // setState({
        //   file: file,
        //   imagePreviewUrl: reader.result
        // });
      };
      reader.readAsDataURL(addedFile);

      // setState({ ...state, file: addedFile, hightlight: false });
      // if (onFileAdded) onFileAdded(addedFile);
    }
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (disabled) return;
    setState({ ...state, hightlight: true });
  };

  const onDragLeave = () => {
    setState({ ...state, hightlight: false });
  };

  const invalid = error !== undefined;

  const dropzoneStyle = {
    // ...AppMediaFileInputStyle,
    // marginBottom: "1em",
    cursor: disabled ? "default" : "pointer",
    border: file ? solidBorder : dashedBorder,
    // backgroundColor: hightlight ? "rgb(188, 185, 236)" : "transparent",
  };

  const hasButton = btnChangeText !== undefined && btnText !== undefined;
  const buttonText = file ? btnChangeText : btnText;

  return (
    <>
      <FormGroup className="text-center">
        {label && <Label for="key_file">{label}</Label>}

        <input
          className={`form-control ${invalid ? "is-invalid" : ""}`}
          ref={fileInputRef}
          style={fileInputStyle}
          type="file"
          accept={accept}
          multiple={multiple}
          onChange={handleFileAdded}
          name="key_file"
        />
        <div
          title="Click to change"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={handleFileDropped}
          onClick={openFileDialog}
          role="button"
          tabIndex={0}
          style={dropzoneStyle}
        >
          {type === "image" && (
            <div style={imageWrapperStyle}>
              <div style={imageContainerStyle}>
                <img style={imageStyle} src={mediaSource} alt="Upload" />
              </div>
            </div>
          )}

          {type === "video" && (
            <div style={videoWrapperStyle}>
              <div style={videoContainerStyle}>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  ref={videoRef}
                  style={videoStyle}
                  controls
                  preload="auto"
                >
                  <source src={mediaSource} />
                  <p>Your browser does not support HTML5 video.</p>
                </video>
              </div>
            </div>
          )}
        </div>
        {error && <FormFeedback>{error}</FormFeedback>}
      </FormGroup>

      <div
        className="text-center mb-2 small"
        role="button"
        onClick={openFileDialog}
        tabIndex={0}
        title="Click to change image or video"
        style={{
          lineHeight: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "stretch",
          color: "#999",
        }}
      >
        <div>{file ? file.name : <>&nbsp;</>}</div>
      </div>

      {hasButton && (
        <div className="text-center">
          <Button onClick={() => openFileDialog()}>{buttonText}</Button>
        </div>
      )}
    </>
  );
};

export default AppMediaFileInput;

const solidBorder = `1px solid transparent`;
const dashedBorder = `1px dashed #bbb`;

// const AppMediaFileInputStyle: React.CSSProperties = {
//   height: "56.25%",
//   width: "100%",
//   backgroundColor: "#fff",
//   // borderRadius: "50%",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   fontSize: "16px",
//   margin: "auto",
//   marginBottom: "1em",
// };

const fileInputStyle: React.CSSProperties = { display: "none" };

// const iconStyle: React.CSSProperties = {
//   opacity: 0.8,
//   height: "100px",
//   width: "100px",
// };

const videoWrapperStyle: React.CSSProperties = {
  position: "relative",
  paddingTop: "56.25%",
};

const videoContainerStyle: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

const imageWrapperStyle: React.CSSProperties = {
  position: "relative",
  paddingTop: "56.25%",
  height: 0,
};

const imageContainerStyle: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
};

const imageStyle: React.CSSProperties = {
  objectFit: "contain",
  height: "100%",
  width: "100%",
};

const videoStyle: React.CSSProperties = {
  height: "100%",
  width: "100%",
  objectFit: "contain",
};
