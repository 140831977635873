import * as React from "react";
import i18n from "i18next";
import ReactDatetime, { DatetimepickerProps } from "react-datetime";
import {
  FormGroup,
  Label,
  FormFeedback,
  FormText,
  InputProps,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";

interface AppDateTimeProps
  extends Omit<
    Omit<Omit<DatetimepickerProps, "onChange">, "value">,
    "initialValue"
  > {
  label?: string;
  error?: string;
  help?: string;
  epoch?: boolean;
  initialValue?: InputProps["value"];
  value: InputProps["value"];
  disabled?: InputProps["disabled"];
  onChange: (event: React.ChangeEvent<HTMLInputElement> | any) => void;
  name: string;
  datePlaceholder?: string;
  timePlaceholder?: string;
  usage?: "start-date-time" | "end-date-time";
}

/*
    dateFormat?: boolean | string;
    Defines the format for the date. It accepts any moment.js date format.
    If true the date will be displayed using the defaults for the current locale.
    If false the datepicker is disabled and the component can be used as timepicker.
*/
/*
    timeFormat?: boolean | string;
    Defines the format for the time. It accepts any moment.js time format.
    If true the time will be displayed using the defaults for the current locale.
    If false the timepicker is disabled and the component can be used as datepicker.
*/

const ZERO_DATE = "1970-01-01";
const ZERO_TIME = "00:00:00";
const DAY_END_TIME = "23:59:59";
const inputDateFormat = "YYYY-MM-DD";
const inputTimeFormat = "HH:mm:ss";

// inputValue format 2021-08-26T01:14:14.013222
const AppDateTime: React.FunctionComponent<AppDateTimeProps> = ({
  dateFormat = true,
  timeFormat = false,
  datePlaceholder = "Date",
  timePlaceholder = "Time",
  epoch = false,
  name,
  value: inputValue,
  label,
  help,
  error,
  onChange,
  usage = "start-date-time",
  disabled = false,
}) => {
  //   const [state, setState] = React.useState<AppDateTimeState>(initialState);
  //   const ref = React.useRef<HTMLInputElement>(null);

  const invalid = error !== undefined;

  const getDateAndTimeValues = (value: InputProps["value"]) => {
    // Current state = inputValue
    if (!value) return { date: "", time: "" };

    // const xValue = "a2a021-08-26T01:14:14.013222";
    // inputValue format 2021-08-26T01:14:14.013222
    // ^([0-9]{4})-?(1[0-2]|0[1-9])-?(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):?([0-5][0-9]):?([0-5][0-9])$
    //
    // Date and time are in the first 19 characters.
    const dateTime = value?.toString().substr(0, 19);
    const pattern =
      /^([0-9]{4})-?(1[0-2]|0[1-9])-?(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):?([0-5][0-9]):?([0-5][0-9])$/;
    const match = dateTime?.match(pattern);

    if (!match) return { date: "", time: "" };

    return {
      date: `${match[1]}-${match[2]}-${match[3]}`,
      time: `${match[4]}:${match[5]}:${match[6]}`,
    };
  };

  /**
   * Converts the date part of the value coming from the
   * API to Moment using inputDateFormat.
   * @param {string | moment.Moment} value: the date value coming from the API.
   */
  const formatDate = (value: InputProps["value"]) => {
    // API format example: 2021-08-26T01:14:14.013222
    if (!value || !dateFormat) return "";

    if (epoch) return moment.unix(value as number);

    const { date } = getDateAndTimeValues(value);
    // console.log("formatDate", { value, date, dateFormat, inputDateFormat });
    if (!date) return "";

    return moment(date, inputDateFormat);
  };

  /**
   * Converts the time part of the value coming from the
   * API to Moment using inputTimeFormat.
   * @param {string | moment.Moment} value: the date value coming from the API.
   */
  const formatTime = (value: InputProps["value"]) => {
    // API format example: 2021-08-26T01:14:14.013222
    if (!value || !timeFormat) return "";

    if (epoch) return moment.unix(value as number);

    const { time } = getDateAndTimeValues(value);
    // console.log("formatTime", { value, time, dateFormat, inputDateFormat });
    if (!time) return "";

    return moment(time, inputTimeFormat);
  };

  /**
   * Converts the date part of the value from the DatePicker to a string.
   * @param {string | moment.Moment} dateValue: the value returned by ReactDateTime in onChange.
   */
  const parseDate = (dateValue: string | moment.Moment) => {
    const parsedDate =
      typeof dateValue === "object" && dateValue.format
        ? dateValue.format(inputDateFormat)
        : dateValue.toString();

    const { time } = getDateAndTimeValues(inputValue);
    const defaulTime = usage === "start-date-time" ? ZERO_TIME : DAY_END_TIME;
    const timeValue = !timeFormat
      ? defaulTime
      : time && time !== ""
      ? time
      : defaulTime;

    const value = parsedDate !== "" ? `${parsedDate}T${timeValue}` : null;

    // console.log("parseDate", {
    //   parsedDate,
    //   dateValue,
    //   value,
    //   time,
    //   timeValue,
    //   usage,
    // });

    if (!onChange) return;

    onChange({ target: { name, value } });
  };

  /**
   * Converts the time part of the value from the TimePicker to a string.
   * @param {string | moment.Moment} timeValue: the value returned by ReactDateTime in onChange.
   */
  const parseTime = (timeValue: string | moment.Moment) => {
    const parsedTime =
      typeof timeValue === "object" && timeValue.format
        ? timeValue.format(inputTimeFormat)
        : timeValue.toString();

    const { date } = getDateAndTimeValues(inputValue);
    const dateValue = !dateFormat
      ? ZERO_DATE
      : date && date !== ""
      ? date
      : moment(new Date()).format(inputDateFormat);

    const value = parsedTime !== "" ? `${dateValue}T${parsedTime}` : null;

    // console.log("parseTime", {
    //   timeValue,
    //   parsedTime,
    //   date,
    //   dateValue,
    //   value,
    // });

    if (!onChange) return;

    onChange({ target: { name, value } });
  };

  //   console.log({ inputValue });

  return (
    <>
      <Row form>
        {dateFormat && (
          <Col>
            <FormGroup className={invalid ? "is-invalid" : ""}>
              {label && <Label for={name}>{i18n.t(label)}</Label>}

              <ReactDatetime
                closeOnSelect
                dateFormat
                timeFormat={false}
                inputProps={{
                  name,
                  className: "form-control",
                  placeholder: datePlaceholder,
                  disabled,
                }}
                value={formatDate(inputValue)}
                onChange={parseDate}
              />
            </FormGroup>
          </Col>
        )}

        {timeFormat && (
          <Col>
            <FormGroup className={invalid ? "is-invalid" : ""}>
              {label && dateFormat && <Label for={name}>&nbsp;</Label>}
              {label && !dateFormat && (
                <Label for={name}>{i18n.t(label)}</Label>
              )}

              <ReactDatetime
                closeOnSelect
                dateFormat={false}
                timeFormat
                inputProps={{
                  name,
                  className: "form-control",
                  placeholder: timePlaceholder,
                  disabled,
                }}
                value={formatTime(inputValue)}
                onChange={parseTime}
              />
            </FormGroup>
          </Col>
        )}
      </Row>
      <Row form>
        <Col>
          <FormGroup className={invalid ? "is-invalid" : ""}>
            {error && <FormFeedback>{error}</FormFeedback>}
            {help && <FormText>{help}</FormText>}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default AppDateTime;
