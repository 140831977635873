import React from "react";
import {
  FormGroup,
  Label,
  FormFeedback,
  Input,
  InputProps,
  FormText,
} from "reactstrap";
import i18n from "i18next";

export interface AppInputProps extends InputProps {
  label?: string;
  error?: string;
  help?: string;
  focus?: boolean;
}

const AppInput: React.FC<AppInputProps> = ({
  name,
  label,
  value,
  error,
  help,
  focus,
  type = "text",
  ...rest
}) => {
  const invalid = error !== undefined;
  const inputRef = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    const abortController = new AbortController();

    if (!inputRef) return;
    if (focus) inputRef.current?.focus();

    return () => abortController.abort();
  }, [focus, inputRef]);

  return (
    <FormGroup>
      {label && <Label for={name}>{i18n.t(label)}</Label>}
      <Input
        innerRef={inputRef}
        invalid={invalid}
        value={value || ""}
        name={name}
        id={name}
        type={type}
        {...rest}
      />

      {error && <FormFeedback>{i18n.t(error)}</FormFeedback>}
      {help && <FormText>{i18n.t(help)}</FormText>}
    </FormGroup>
  );
};

/* 
    <FormGroup>
        <Label for="examplePassword">Invalid input</Label>
        <Input invalid />
        <FormFeedback>Oh noes! that name is already taken</FormFeedback>
        <FormText>Example help text that remains unchanged.</FormText>
    </FormGroup>; 
*/

AppInput.defaultProps = {};

export default AppInput;
