import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import i18n from "i18next";

import logoLight from "assets/img/logo_light.png";
import logoDark from "assets/img/logo_dark.png";
import { SelectOption, defaultBGMode } from "config";
import colors from "./colors";
import { apiCallBegan } from "./apiActions";

// pages: { [key in AppPagesEnum]: { [key: string]: any } }
// eslint-disable-next-line no-shadow
enum AppPagesEnum {
  Displays = "displays",
  Messages = "messages",
  SpotClusters = "spotClusters",
  Publishing = "publishing",
}

export interface UIState {
  isFullScreen: boolean;
  lists: { [key: string]: any[] };
  pages: { [key: string]: any };
  loading: boolean;
  sidebarOpened: boolean;
  backgroundMode: string;
}

const initialState: UIState = {
  isFullScreen: false,
  lists: {},
  pages: {},
  loading: false,
  sidebarOpened: false,
  backgroundMode: "light",
};
const name = "ui";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    requestSent: (state) => {
      state.loading = true;
    },
    requestFailed: (state) => {
      state.loading = false;
    },
    fullScreenToggled: (state) => {
      state.isFullScreen = !state.isFullScreen;
    },
    fullScreenChanged: (state, action) => {
      state.isFullScreen = action.payload;
    },
    sidebarToggled: (state) => {
      state.sidebarOpened = !state.sidebarOpened;
    },
    backgroundModeChanged: (state, action) => {
      state.backgroundMode = action.payload;
      localStorage.setItem("backgroundMode", action.payload);
    },
    listsLoaded: (state, action) => {
      const lists = action.payload;

      // TO ADD SELECT ITEM ROWS
      // const select = { value: null, text: i18next.t('app.selectItem') };
      // for (let key of Object.keys(lists)) {
      //     // Only add (Select) option to lists with more than 1 item.
      //     if (lists[key].length > 1) lists[key].unshift(select);
      // }

      state.lists = lists;
      state.loading = false;
    },
    itemAddedToList: (
      state,
      action: PayloadAction<{ value: string; label: string; listName: string }>
    ) => {
      const { listName, value, label } = action.payload;
      // This is necessary to clone the existing list.
      const listItems = state.lists[listName].map((item) => ({ ...item }));
      listItems.push({ value, label });
      state.lists[listName] = listItems;
      // console.log({
      //   listName,
      //   value,
      //   label,
      //   lists: Object.keys(state.lists),
      //   counter: state.lists[listName].length,
      //   listItems,
      // });
    },
    itemRemovedFromList: (
      state,
      action: PayloadAction<{ value: string; label: string; listName: string }>
    ) => {
      const { listName, value } = action.payload;
      // This is necessary to clone the existing list.
      const listItems = state.lists[listName].filter((item) => {
        const listItem = { ...item };
        return listItem.value !== value;
      });
      state.lists[listName] = listItems;
    },
    pageUpdated: (state, action) => {
      const { pageName, data } = action.payload;
      state.pages[pageName] = data;
      // console.log(action.payload);
      //   state.pages = _.extend(state.pages, action.payload);
    },
  },
});

// Export Reducer and Actions
export default slice.reducer;
export const { actions } = slice;

// Action Creators
export const setFullScreen = (value: boolean) =>
  actions.fullScreenChanged(value);

export const toggleFullScreen = () => actions.fullScreenToggled();

export const toggleSidebar = () => actions.sidebarToggled();

export const loadLists = () => {
  const url = "/lists";
  return apiCallBegan({
    url,
    onStart: actions.requestSent.type,
    onSuccess: actions.listsLoaded.type,
    onError: actions.requestFailed.type,
  });
};

export const setBackgroundMode = (mode: string) => {
  if (mode === "light") {
    document.documentElement.setAttribute("dark", "false");
    document.body.classList.add("white-content");
  } else {
    document.documentElement.setAttribute("dark", "true");
    document.body.classList.remove("white-content");
  }

  return actions.backgroundModeChanged(mode);
  // TODO: SET PREFERENCES
};

export const addDisplay = (option: { value: string; label: string }) =>
  actions.itemAddedToList({ ...option, listName: "displays" });

export const removeDisplay = (option: { value: string; label: string }) =>
  actions.itemRemovedFromList({ ...option, listName: "displays" });

export const addDisplayGroup = (option: { value: string; label: string }) =>
  actions.itemAddedToList({ ...option, listName: "display_groups" });

export const removeDisplayGroup = (option: { value: string; label: string }) =>
  actions.itemRemovedFromList({ ...option, listName: "display_groups" });

export const loadPreferences = () => {
  const value = localStorage.getItem("backgroundMode");
  const bgMode = value || defaultBGMode;
  return setBackgroundMode(bgMode);
};

export const updateMessagesPage = (data: { [key: string]: any }) =>
  actions.pageUpdated({ pageName: AppPagesEnum.Messages, data });

export const updatePublishingPage = (data: { [key: string]: any }) =>
  actions.pageUpdated({ pageName: AppPagesEnum.Publishing, data });

export const updateDisplaysPage = (data: { [key: string]: any }) =>
  actions.pageUpdated({ pageName: AppPagesEnum.Displays, data });

export const updateSpotClustersPage = (data: { [key: string]: any }) =>
  actions.pageUpdated({ pageName: AppPagesEnum.SpotClusters, data });

// export const updatePage = (pageName: string, data: { [key: string]: any }) =>
//   actions.pageUpdated({ pageName, data });

export const creators = {
  setFullScreen,
  setBackgroundMode,
  toggleFullScreen,
  toggleSidebar,
  loadLists,
  loadPreferences,
  //   updatePage,
  updateMessagesPage,
  addDisplayGroup,
  removeDisplayGroup,
};

export const scrollToTop = () => {
  if (!document) return;

  const mainPanel = document.querySelector("div.main-panel");
  if (!mainPanel) return;

  mainPanel.scrollTo(0, 0);
};

// Create Selectors (Memoization)
export const selectUIState = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui
);

export const selectSpotClustersPageData = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.pages[AppPagesEnum.SpotClusters]
);

export const selectDisplaysPageData = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.pages[AppPagesEnum.Displays]
);

export const selectMessagesPageData = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.pages[AppPagesEnum.Messages]
);

export const selectPublishingPageData = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.pages[AppPagesEnum.Publishing]
);

export const selectBackgroundMode = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.backgroundMode
);

export const selectLogoImage = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => (ui.backgroundMode === "dark" ? logoDark : logoLight)
);

export const selectColors = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => colors[ui.backgroundMode]
);

export const selectMessageProfilesList = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.lists.message_profiles
);

// export const getMessageProfilesList = createSelector(
//   (state: { [key: string]: any }) => state.ui,
//   (ui) => {
//     let options: SelectOption[] = [];
//     if (ui.lists.message_profiles && _.isArray(ui.lists.message_profiles)) {
//       options = ui.lists.message_profiles.slice(0);
//       options.unshift({
//         value: "",
//         label: i18n.t("label.select_option"),
//       });
//     }
//     return options;
//   }
// );

export const selectDisplaysList = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.lists.displays
);

export const selectDisplayGroupsList = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.lists.display_groups
);

export const selectDisplaysAndGroupsList = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => {
    // react-select groups example.
    // const options = [
    //   {
    //     label: "Group 1",
    //     options: [
    //       { label: "Group 1, option 1", value: "value_1" },
    //       { label: "Group 1, option 2", value: "value_2" }
    //     ]
    //   },
    //   { label: "A root option", value: "value_3" },
    //   { label: "Another root option", value: "value_4" }
    // ];

    const { displays, display_groups } = ui.lists;
    const options: Record<string, any>[] = [];

    if (display_groups.length > 0) {
      const listItems = display_groups.map(
        (item: { value: string; label: string }) => ({ ...item })
      );
      options.push({ label: i18n.t("label.groups"), options: listItems });
    }

    if (displays.length > 0) {
      const listItems = displays.map(
        (item: { value: string; label: string }) => ({ ...item })
      );
      options.push({ label: i18n.t("label.displays"), options: listItems });
    }

    return options;
  }
);

export const selectCountriesList = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.lists.countries
);

export const selectTimezonesList = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.lists.timezones
);

export const selectVoiceGendersList = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => ui.lists.voice_genders
);

export const selectLanguageCodesList = createSelector(
  (state: { [key: string]: any }) => state.ui,
  (ui) => {
    let options: SelectOption[] = [];
    if (ui.lists.language_codes && _.isArray(ui.lists.language_codes)) {
      options = ui.lists.language_codes.slice(0);
      options.unshift({ value: "", label: "(Select)" });
    }
    return options;
  }
);
