/* eslint @typescript-eslint/no-var-requires: "off" */
/* eslint import/no-dynamic-require: 0 */
/* eslint global-require: 0 */

const defaultImage: { [key: string]: any } = {
  portrait: require("assets/img/thumbnail_portrait.png").default,
  landscape: require("assets/img/thumbnail_landscape.png").default,
};

const settings = {
  development: {
    pricing: {
      monthly: [
        {
          id: "starter",
          name: "Starter Pack",
          description: "For Mediums teams or group who need to build website ",
          price: "$8",
          priceLabel: "Per display per month",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "5 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: false,
            },
            {
              content: "email display status alerts",
              available: false,
            },
            {
              content: "SMS display status alerts",
              available: false,
            },
            {
              content: "Teams delegation",
              available: false,
            },
          ],
        },
        {
          id: "premium",
          name: "Premium Pack",
          description: "For Mediums teams or group who need to build website ",
          price: "$12",
          priceLabel: "Per display per month",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "10 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: true,
            },
            {
              content: "email display status alerts",
              available: true,
            },
            {
              content: "SMS display status alerts",
              available: false,
            },
            {
              content: "Teams delegation",
              available: false,
            },
          ],
        },
        {
          id: "ultimate",
          name: "Ultimate Pack",
          description: "For Large teams or group who need to build website ",
          price: "$20",
          priceLabel: "Per display per month",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "50 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: true,
            },
            {
              content: "email display status alerts",
              available: true,
            },
            {
              content: "SMS display status alerts",
              available: true,
            },
            {
              content: "Teams delegation",
              available: true,
            },
          ],
        },
      ],
      yearly: [
        {
          id: "starter",
          name: "Starter Pack",
          description: "For Small teams or group who need to build website ",
          price: "$72",
          priceLabel: "Per display per year (save 25%)",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "5 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: false,
            },
            {
              content: "email display status alerts",
              available: false,
            },
            {
              content: "SMS display status alerts",
              available: false,
            },
            {
              content: "Teams delegation",
              available: false,
            },
          ],
        },
        {
          id: "premium",
          name: "Premium Pack",
          description: "For Small teams or group who need to build website ",
          price: "$108",
          priceLabel: "Per display per year (save 25%)",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "10 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: true,
            },
            {
              content: "email display status alerts",
              available: true,
            },
            {
              content: "SMS display status alerts",
              available: false,
            },
            {
              content: "Teams delegation",
              available: false,
            },
          ],
        },
        {
          id: "ultimate",
          name: "Ultimate Pack",
          description: "For Large teams or group who need to build website ",
          price: "$180",
          priceLabel: "Per display per year (save 25%)",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "50 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: true,
            },
            {
              content: "email display status alerts",
              available: true,
            },
            {
              content: "SMS display status alerts",
              available: true,
            },
            {
              content: "Teams delegation",
              available: true,
            },
          ],
        },
      ],
    },

    defaultBGMode: "dark",
    monitorTimeout: 60, // seconds
    pollingInterval: 10, // seconds

    collectJs: {
      key: "NjkdY5-JD34Az-rr46SS-Qsk8HR",
      url: "https://secure.nmi.com/token/Collect.js",
      //   url: "https://secure.networkmerchants.com/token/Collect.js",
    },

    paddle: {
      vendor: 3466,
      sandbox: true,
      apiKey: "d6088bd9fd4412bf5a43ac9e2e1274aa",
      url: "https://cdn.paddle.com/paddle/paddle.js",
    },
    apiUrl: "https://devapp.signkontrol.com:3578/api/v1",
    logoutUrl: "https://devapp.signkontrol.com:3578/api/v1/logout",
    logEndpoint: "https://3e8a556cb7844d6293a11109dc64ec9a@sentry.io/1364072",
    invoiceUrl: "https://devapp.signkontrol.com:3578/api/v1/billing/invoice",
    usageUrl: "https://devapp.signkontrol.com:3578/api/v1/billing/usage",
    tokenKey: "signkontrol-app-token",
    appTitle: "SignKontrol",
    // tol
    // tol-dv
    // cb-PuRd
    // cb-Purples
    // cb-RdPu
    // cb-BuPu
    // cb-PuRd
    // cb-Purples
    // cb-RdPu
    chartsColors: {
      palette: "cb-PuRd",
      normalAlpha: 1.0,
      hoverAlpha: 0.9,
    },
    oauth: {
      github: {
        url: "https://devapp.signkontrol.com:3578/api/v1/register/github",
      },
      google: {
        url: "https://devapp.signkontrol.com:3578/api/v1/register/google",
      },
    },
    copyright: {
      company: "Libereco Systems",
      startYear: 2020,
    },
    paypal: {
      clientId:
        "AWz3k3dGHYQmok3m7eQza2-ACFPReRKVCLYSP9mG6AOG-wahAr48uNtkbfNG5_BRfQAHYENTPzrYuCs5",
      currency_code: "USD",
      prepaidOptions: [
        { value: "10.0", label: "USD $10" },
        { value: "25.0", label: "USD $25" },
        { value: "50.0", label: "USD $50" },
        { value: "100.0", label: "USD $100" },
        { value: "200.0", label: "USD $200" },
        { value: "500.0", label: "USD $500" },
        { value: "1000.0", label: "USD $1000" },
      ],
      custom: {
        min: 5,
        max: 2000,
        step: 0.01,
      },
    },
    sdk: {
      url: "https://devapp.signkontrol.com:3578/api/v1/sdk/js",
    },
    ui: {
      layoutName: "/console",
      initialPage: "/console/publishing",
      sideBarColor: "primary",
      logoColor: "#50a2f7",
      listsKey: "signkontrol-app-lists",
    },
    defaultImage,
    lists: {
      displayUsage: [
        { value: "default", label: "label.default" },
        { value: "elevator", label: "label.elevator" },
        { value: "mobile", label: "label.mobile" },
      ],
      orientations: [
        { value: "landscape", label: "label.landscape" },
        { value: "portrait", label: "label.portrait" },
      ],
      resolutionTolerances: [
        { value: "0.05", label: "5%" },
        { value: "0.10", label: "10%" },
        { value: "0.15", label: "15%" },
        { value: "0.20", label: "20%" },
      ],
      os: [
        { value: "android", label: "label.android" },
        { value: "linux", label: "label.linux" },
        { value: "windows", label: "label.windows" },
      ],
      applicationTargets: [
        { value: "S", label: "SIP Trunk" },
        { value: "C", label: "Chatbot" },
      ],
      sortAlgs: [
        { value: "W", label: "Random" },
        { value: "N", label: "Sequential" },
      ],
      voiceGenders: [
        { value: "SSML_VOICE_GENDER_FEMALE", label: "Female" },
        { value: "SSML_VOICE_GENDER_MALE", label: "Male" },
        { value: "SSML_VOICE_GENDER_NEUTRAL", label: "Neutral" },
        { value: "SSML_VOICE_GENDER_UNSPECIFIED", label: "Unspecified" },
      ],
      languages: [
        { value: "zh-CN", label: "Chinese - Simplified" },
        { value: "da", label: "Danish" },
        { value: "nl", label: "Dutch" },
        { value: "en", label: "English" },
        { value: "en-AU", label: "English - Australia" },
        { value: "en-CA", label: "English - Canada" },
        { value: "en-GB", label: "English - Great Britain" },
        { value: "en-IN", label: "English - India" },
        { value: "en-US", label: "English - US" },
        { value: "fr-CA", label: "French - Canada" },
        { value: "fr-FR", label: "French - France" },
        { value: "de", label: "German" },
        { value: "hi", label: "Hindi" },
        { value: "id", label: "Indonesian" },
        { value: "it", label: "Italian" },
        { value: "ja", label: "Japanese" },
        { value: "ko", label: "Korean" },
        { value: "no", label: "Norwegian" },
        { value: "pl", label: "Polish" },
        { value: "pt-BR", label: "Portuguese - Brazil" },
        { value: "pt", label: "Portuguese - Portugal" },
        { value: "ru", label: "Russian" },
        { value: "es", label: "Spanish" },
        { value: "es-ES", label: "Spanish - Spain" },
        { value: "sv", label: "Swedish" },
        { value: "tr", label: "Turkish" },
        { value: "uk", label: "Ukrainian" },
      ],
    },
  },
  production: {
    pricing: {
      monthly: [
        {
          id: "starter",
          name: "Starter Pack",
          description: "For Mediums teams or group who need to build website ",
          price: "$8",
          priceLabel: "Per display per month",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "5 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: false,
            },
            {
              content: "email display status alerts",
              available: false,
            },
            {
              content: "SMS display status alerts",
              available: false,
            },
            {
              content: "Teams delegation",
              available: false,
            },
          ],
        },
        {
          id: "premium",
          name: "Premium Pack",
          description: "For Mediums teams or group who need to build website ",
          price: "$12",
          priceLabel: "Per display per month",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "10 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: true,
            },
            {
              content: "email display status alerts",
              available: true,
            },
            {
              content: "SMS display status alerts",
              available: false,
            },
            {
              content: "Teams delegation",
              available: false,
            },
          ],
        },
        {
          id: "ultimate",
          name: "Ultimate Pack",
          description: "For Large teams or group who need to build website ",
          price: "$20",
          priceLabel: "Per display per month",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "50 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: true,
            },
            {
              content: "email display status alerts",
              available: true,
            },
            {
              content: "SMS display status alerts",
              available: true,
            },
            {
              content: "Teams delegation",
              available: true,
            },
          ],
        },
      ],
      yearly: [
        {
          id: "starter",
          name: "Starter Pack",
          description: "For Small teams or group who need to build website ",
          price: "$72",
          priceLabel: "Per display per year (save 25%)",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "5 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: false,
            },
            {
              content: "email display status alerts",
              available: false,
            },
            {
              content: "SMS display status alerts",
              available: false,
            },
            {
              content: "Teams delegation",
              available: false,
            },
          ],
        },
        {
          id: "premium",
          name: "Premium Pack",
          description: "For Small teams or group who need to build website ",
          price: "$108",
          priceLabel: "Per display per year (save 25%)",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "10 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: true,
            },
            {
              content: "email display status alerts",
              available: true,
            },
            {
              content: "SMS display status alerts",
              available: false,
            },
            {
              content: "Teams delegation",
              available: false,
            },
          ],
        },
        {
          id: "ultimate",
          name: "Ultimate Pack",
          description: "For Large teams or group who need to build website ",
          price: "$180",
          priceLabel: "Per display per year (save 25%)",
          buttonLabel: "Start your free trial",
          url: "https://app.signkontrol.com/register",
          listItems: [
            {
              content: "50 GB cloud content storage",
              available: true,
            },
            {
              content: "Displays monitoring",
              available: true,
            },
            {
              content: "Proof of play",
              available: true,
            },
            {
              content: "email display status alerts",
              available: true,
            },
            {
              content: "SMS display status alerts",
              available: true,
            },
            {
              content: "Teams delegation",
              available: true,
            },
          ],
        },
      ],
    },

    defaultBGMode: "dark",
    monitorTimeout: 180, // seconds
    pollingInterval: 10, // seconds

    collectJs: {
      key: "NjkdY5-JD34Az-rr46SS-Qsk8HR",
      url: "https://secure.nmi.com/token/Collect.js",
      //   url: "https://secure.networkmerchants.com/token/Collect.js",
    },

    paddle: {
      vendor: 136359,
      apiKey: "09e8e963917a6f6a38c74aa7828015ac",
      url: "https://cdn.paddle.com/paddle/paddle.js",
    },
    apiUrl: "https://app.signkontrol.com/api/v1",
    logoutUrl: "https://app.signkontrol.com/api/v1/logout",
    logEndpoint: "https://3e8a556cb7844d6293a11109dc64ec9a@sentry.io/1364072",
    invoiceUrl: "https://app.signkontrol.com/api/v1/billing/invoice",
    usageUrl: "https://app.signkontrol.com/api/v1/billing/invoice",
    tokenKey: "signkontrol-app-token",
    appTitle: "SignKontrol",
    // colorPalette: "tol-dv",
    chartsColors: {
      palette: "tol-dv",
      normalAlpha: 1.0,
      hoverAlpha: 0.9,
    },
    oauth: {
      github: {
        url: "https://app.signkontrol.com/api/v1/register/github",
      },
      google: {
        url: "https://app.signkontrol.com/api/v1/register/google",
      },
    },
    copyright: {
      company: "Libereco Systems",
      startYear: 2020,
    },
    paypal: {
      clientId:
        "AWz3k3dGHYQmok3m7eQza2-ACFPReRKVCLYSP9mG6AOG-wahAr48uNtkbfNG5_BRfQAHYENTPzrYuCs5",
      currency_code: "USD",
      prepaidOptions: [
        { value: "10.0", label: "USD $10" },
        { value: "25.0", label: "USD $25" },
        { value: "50.0", label: "USD $50" },
        { value: "100.0", label: "USD $100" },
        { value: "200.0", label: "USD $200" },
        { value: "500.0", label: "USD $500" },
        { value: "1000.0", label: "USD $1000" },
      ],
      custom: {
        min: 5,
        max: 2000,
        step: 0.01,
      },
    },
    sdk: {
      url: "https://sdk.signkontrol.com/js",
    },
    ui: {
      layoutName: "/console",
      initialPage: "/console/publishing",
      sideBarColor: "primary",
      logoColor: "#50a2f7",
      listsKey: "signkontrol-app-lists",
    },
    defaultImage,
    lists: {
      displayUsage: [
        { value: "default", label: "label.default" },
        { value: "elevator", label: "label.elevator" },
        { value: "mobile", label: "label.mobile" },
      ],
      orientations: [
        { value: "landscape", label: "label.landscape" },
        { value: "portrait", label: "label.portrait" },
      ],
      resolutionTolerances: [
        { value: "0.05", label: "5%" },
        { value: "0.10", label: "10%" },
        { value: "0.15", label: "15%" },
        { value: "0.20", label: "20%" },
      ],
      os: [
        { value: "android", label: "label.android" },
        { value: "linux", label: "label.linux" },
        { value: "windows", label: "label.windows" },
      ],
      applicationTargets: [
        { value: "S", label: "SIP Trunk" },
        { value: "C", label: "Chatbot" },
      ],
      sortAlgs: [
        { value: "W", label: "Random" },
        { value: "N", label: "Sequential" },
      ],
      voiceGenders: [
        { value: "SSML_VOICE_GENDER_FEMALE", label: "Female" },
        { value: "SSML_VOICE_GENDER_MALE", label: "Male" },
        { value: "SSML_VOICE_GENDER_NEUTRAL", label: "Neutral" },
        { value: "SSML_VOICE_GENDER_UNSPECIFIED", label: "Unspecified" },
      ],
      languages: [
        { value: "zh-CN", label: "Chinese - Simplified" },
        { value: "da", label: "Danish" },
        { value: "nl", label: "Dutch" },
        { value: "en", label: "English" },
        { value: "en-AU", label: "English - Australia" },
        { value: "en-CA", label: "English - Canada" },
        { value: "en-GB", label: "English - Great Britain" },
        { value: "en-IN", label: "English - India" },
        { value: "en-US", label: "English - US" },
        { value: "fr-CA", label: "French - Canada" },
        { value: "fr-FR", label: "French - France" },
        { value: "de", label: "German" },
        { value: "hi", label: "Hindi" },
        { value: "id", label: "Indonesian" },
        { value: "it", label: "Italian" },
        { value: "ja", label: "Japanese" },
        { value: "ko", label: "Korean" },
        { value: "no", label: "Norwegian" },
        { value: "pl", label: "Polish" },
        { value: "pt-BR", label: "Portuguese - Brazil" },
        { value: "pt", label: "Portuguese - Portugal" },
        { value: "ru", label: "Russian" },
        { value: "es", label: "Spanish" },
        { value: "es-ES", label: "Spanish - Spain" },
        { value: "sv", label: "Swedish" },
        { value: "tr", label: "Turkish" },
        { value: "uk", label: "Ukrainian" },
      ],
    },
  },
};

const getCurrentSettings = (): { [key: string]: any } => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    return settings.development;
  return settings.production;
};

export default getCurrentSettings();
