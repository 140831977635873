import React from "react";

import { Row, Col, Spinner, Button } from "reactstrap";
import i18n from "i18next";
import { useIsXSmall } from "components/forms";
// import { ColumnProps } from "reactstrap/es/Col";

export interface AppOkCancelButtonsProps {
  submitting: boolean;
  pristine: boolean;
  onCancel: () => void;
  rounded?: boolean;
  okText?: string;
  cancelText?: string;
  okColor?: string;
  cancelColor?: string;
  spinnerType?: string;
  spinnerColor?: string;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

export const AppOkCancelButtons: React.FC<AppOkCancelButtonsProps> = ({
  submitting,
  pristine,
  onCancel,
  okText = "save",
  cancelText = "cancel",
  okColor = "primary",
  cancelColor = "secondary",
  rounded = true,
  spinnerType = "grow",
  spinnerColor = "primary",
  xl = 3,
  lg = 3,
  md = 5,
  sm = 5,
  xs = 12,
}) => {
  const isXSmall = useIsXSmall();
  const buttonClass = rounded ? "btn-round" : "";

  const okContainer: React.CSSProperties = {
    paddingLeft: isXSmall ? 0 : 5,
    paddingRight: isXSmall ? 0 : 2,
    marginBottom: isXSmall ? "1.5em" : 0,
  };

  const cancelContainer: React.CSSProperties = {
    paddingLeft: isXSmall ? 0 : 8,
    paddingRight: isXSmall ? 0 : 0,
    // marginBottom: "1.5em",
  };

  const spinnerSizes = {
    xl: 12 - xl * 2,
    lg: 12 - lg * 2,
    md: 12 - md * 2,
    sm: 12 - sm * 2,
    xs: 12 - xs * 2 > 0 ? 12 - xs * 2 : 12,
  };

  return (
    <Row
      form
      style={{
        width: "100%",
        padding: 0,
        margin: 0,
        alignItems: "stretch",
        marginBottom: "0.5rem",
      }}
    >
      <Col
        style={{
          display: "flex",
          justifyContent: isXSmall ? "center" : "end",
          alignItems: "center",
          marginBottom: isXSmall ? "0.5rem" : 0,
        }}
        {...spinnerSizes}
      >
        {submitting && <Spinner type={spinnerType} color={spinnerColor} />}{" "}
      </Col>
      <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} style={okContainer}>
        <Button
          block={isXSmall}
          color={okColor}
          type="submit"
          disabled={submitting || pristine}
          style={{
            width: "100%",
          }}
          className={buttonClass}
        >
          {i18n.t(`button.${okText}`)}
        </Button>
      </Col>
      <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} style={cancelContainer}>
        <Button
          block={isXSmall}
          color={cancelColor}
          onClick={() => onCancel()}
          disabled={submitting}
          style={{
            width: "100%",
          }}
          className={buttonClass}
        >
          {i18n.t(`button.${cancelText}`)}
        </Button>
      </Col>
    </Row>
  );
};

export default AppOkCancelButtons;
