import { configureStore } from "@reduxjs/toolkit";
import api from "./middleware/api";
import logger from "./middleware/logger";
import allReducers from "./allReducers";

export const store = () => configureStore({ reducer: allReducers, middleware: [logger, api] });

export default store;

export type RootState = ReturnType<typeof allReducers>;
