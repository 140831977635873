import React from "react";
import {
  FormGroup,
  Label,
  FormFeedback,
  Input,
  InputProps,
  FormText,
} from "reactstrap";
import i18n from "i18next";

export interface AppCheckboxProps extends InputProps {
  label?: string;
  error?: string;
  help?: string;
}

const AppCheckbox: React.FC<AppCheckboxProps> = ({
  name,
  label,
  checked,
  value,
  error,
  help,
  focus,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type = "checkbox",
  ...rest
}) => {
  const invalid = error !== undefined;
  const inputRef = React.createRef<HTMLInputElement>();

  // console.log({ value, ...rest });

  React.useEffect(() => {
    if (!inputRef) return;
    if (focus) inputRef.current?.focus();
  }, [focus, inputRef]);

  return (
    <>
      <FormGroup check>
        <Label check>
          <Input
            innerRef={inputRef}
            invalid={invalid}
            value={value || undefined}
            checked={checked}
            name={name}
            id={name}
            type="checkbox"
            {...rest}
          />
          <span className="form-check-sign" />
          {label && i18n.t(label)}
        </Label>
      </FormGroup>

      {error && <FormFeedback>{i18n.t(error)}</FormFeedback>}
      {help && <FormText>{i18n.t(help)}</FormText>}
    </>
  );
};

/* 
    <FormGroup check>
      <Label check>
        <Input type="checkbox" />
        <span className="form-check-sign" />
        First Checkbox
      </Label>
    </FormGroup>
*/

export default AppCheckbox;
