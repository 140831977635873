import * as Sentry from "@sentry/browser";

import settings from "config/settings";

const init = () => {
  // should have been called before using it here
  // ideally before even rendering your react app
  Sentry.init({
    dsn: settings.logEndpoint,
  });
};

const log = (error: any) => {
  Sentry.captureException(error);
};

export default {
  init,
  log,
};
