import React from "react";
import i18n from "i18next";
import {
  FormGroup,
  Label,
  FormFeedback,
  InputProps,
  FormText,
} from "reactstrap";

import Select, { Props as ReactSelectProps } from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";

// import { colourOptions } from '../data';

// const colourOptions = [
//   { value: "orange", label: "Orange" },
//   { value: "blue", label: "Blue" },
//   { value: "pink", label: "Pink" },
//   { value: "white", label: "White" },
//   { value: "black", label: "Black" },
//   { value: "purple", label: "Purple" },
//   { value: "red", label: "Red" },
//   { value: "yellow", label: "Yellow" },
//   { value: "green", label: "Green" },
// ];

// const filterColors = (inputValue: string) =>
//   colourOptions.filter((i) =>
//     i.label.toLowerCase().includes(inputValue.toLowerCase())
//   );

// const promiseOptions = (inputValue: string) =>
//   new Promise((resolve) => {
//     setTimeout(() => {
//       resolve(filterColors(inputValue));
//     }, 1000);
//   });

// export default class WithPromises extends Component {
//   render() {
//     return (
//       <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions} />
//     );
//   }
// }

export const formatSelected = (value: any, options: any) => {
  //   console.log("formatSelected", { value, options });

  if (value === null || value === undefined) return "";

  if (Array.isArray(value)) {
    return value.map((key) => {
      if (options.length > 0 && options[0].options) {
        // Nested options (groups)
        for (let i = 0; i < options.length; i += 1) {
          const found = options[i].options.find(
            (item: { value: string; label: string }) => item.value === key
          );

          if (found) return { value: key, label: found.label };
        }

        return { value: key, label: key };
      }

      const found = options.find(
        (item: { value: string; label: string }) => item.value === key
      );
      return { value: key, label: found ? found.label : key };
    });
  }

  // If selected value is an OptionType object { value:string; label:string; }, return it.
  if (typeof value === "object" && value.value && value.label) return value;

  const found = options.find(
    (item: {
      value?: string;
      label: string;
      options?: { value: string; label: string }[];
    }) => {
      if (item.options)
        // Groups
        return (
          item.options.find(
            (nestedItem: { value: string; label: string }) =>
              nestedItem.value === value
          ) !== undefined
        );

      return item.value === value;
    }
  );

  // console.log("formatSelected", { value, options, found });

  return {
    value: `${value}`,
    label: found ? found.label : `${value}`,
  };
};

export const parseSelected = (option: any) => {
  // console.log("parseSelected", { option });

  if (option === null) return;

  if (Array.isArray(option))
    return option.map((item: { value: any; label: any }) => item.value);

  return option.value ? option.value : "";
};

export interface AppReactSelectOption {
  value: string;
  label: string;
}

export interface AppReactSelectProps extends Omit<ReactSelectProps, "value"> {
  label?: string;
  error?: string;
  help?: string;
  value: InputProps["value"];
  creatable?: boolean;
  parseOptions?: boolean;
  formatOptions?: boolean;
  disabled?: boolean;
}

export const AppReactSelect: React.FC<AppReactSelectProps> = ({
  name,
  label,
  value,
  options,
  error,
  help,
  onChange,
  creatable = false,
  isLoading,
  placeholder,
  loadOptions = undefined,
  parseOptions = true,
  formatOptions = true,
  disabled = false,
  ...rest
}) => {
  const invalid = error !== undefined;
  const componentPlaceholder =
    placeholder !== undefined ? i18n.t(placeholder) : undefined;

  const parse = (option: any) => {
    if (!onChange) return;

    const val = parseOptions ? parseSelected(option) : option;

    // console.log({ val, option, onChange, parsed: parseSelected(option) });

    onChange({ target: { name, value: val } });
  };

  return (
    <FormGroup className={invalid ? "is-invalid" : ""}>
      {label && <Label for={name}>{i18n.t(label)}</Label>}

      {/* <AsyncSelect cacheOptions defaultOptions loadOptions={promiseOptions} /> */}

      {creatable ? (
        <>
          {loadOptions === undefined ? (
            <CreatableSelect
              className="react-select info"
              classNamePrefix="react-select"
              id={name}
              name={name}
              options={options}
              isClearable
              isDisabled={isLoading || disabled}
              isLoading={isLoading}
              invalid={invalid}
              value={formatOptions ? formatSelected(value, options) : value}
              onChange={parse}
              placeholder={componentPlaceholder}
              {...rest}
            />
          ) : (
            <AsyncCreatableSelect
              className="react-select info"
              classNamePrefix="react-select"
              id={name}
              name={name}
              options={options}
              isClearable
              isDisabled={isLoading || disabled}
              isLoading={isLoading}
              invalid={invalid}
              value={formatOptions ? formatSelected(value, options) : value}
              onChange={parse}
              placeholder={componentPlaceholder}
              loadOptions={loadOptions}
              cacheOptions
              {...rest}
            />
          )}
        </>
      ) : (
        <>
          {loadOptions === undefined ? (
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              id={name}
              name={name}
              isDisabled={disabled}
              options={options}
              invalid={invalid}
              value={formatOptions ? formatSelected(value, options) : value}
              onChange={parse}
              placeholder={componentPlaceholder}
              {...rest}
            />
          ) : (
            <AsyncSelect
              className="react-select info"
              classNamePrefix="react-select"
              id={name}
              name={name}
              isDisabled={disabled}
              options={options}
              invalid={invalid}
              value={formatOptions ? formatSelected(value, options) : value}
              onChange={parse}
              placeholder={componentPlaceholder}
              loadOptions={loadOptions}
              cacheOptions
              {...rest}
            />
          )}
        </>
      )}

      {error && <FormFeedback>{error}</FormFeedback>}
      {help && <FormText>{help}</FormText>}
    </FormGroup>
  );
};

export default AppReactSelect;
