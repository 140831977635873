import { useMediaQuery } from "react-responsive";

export { default as Loading } from "./Loading";
export { default as Spinner } from "./Spinner";
export { default as AppInput } from "./AppInput";
export { default as AppFileInput } from "./AppFileInput";
export { default as AppArrayInput } from "./AppArrayInput";
export { default as AppConfirm } from "./AppConfirm";
export { default as AppMediaFileInput } from "./AppMediaFileInput";
export { default as AppOkCancelButtons } from "./AppOkCancelButtons";
export { default as AppCheckbox } from "./AppCheckbox";
export { default as AppSelect } from "./AppSelect";
export { default as AppReactSelect } from "./AppReactSelect";
export { default as AppPayPalPayment } from "./AppPayPalPayment";
export { default as AppDateTime } from "./AppDateTime";
export { default as ReactWizard } from "./ReactWizard";
export type { ReactWizardStep } from "./ReactWizard";
export type { ReactWizardStepState } from "./ReactWizard";

// Extra small:     <576px
// Small:           ≥576px
// Medium:          ≥768px
// Large:           ≥992px
// Extra large:     ≥1200px
export type DeviceSize = "xl" | "lg" | "md" | "sm" | "xs";

export const useIsXSmall = () => useMediaQuery({ maxWidth: 575 });
export const useIsSmall = () => useMediaQuery({ minWidth: 576 });
export const useIsMedium = () => useMediaQuery({ minWidth: 768 });
export const useIsLarge = () => useMediaQuery({ minWidth: 992 });
export const useIsXLarge = () => useMediaQuery({ minWidth: 1200 });

export const useDeviceSize = (): DeviceSize => {
  // We have to call all size functions to avoid this error whe screen size change:
  // Warning: React has detected a change in the order of Hooks called react-responsive
  // https://stackoverflow.com/questions/57397395/react-has-detected-a-change-in-the-order-of-hooks-but-hooks-seem-to-be-invoked

  const isXLarge = useIsXLarge();
  const isLarge = useIsLarge();
  const isMedium = useIsMedium();
  const isSmall = useIsSmall();

  if (isXLarge) return "xl";
  if (isLarge) return "lg";
  if (isMedium) return "md";
  if (isSmall) return "sm";
  return "xs";
};
