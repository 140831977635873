import { createSelector } from "@reduxjs/toolkit";

import { Message } from "validation/schemas/message";
import { Publication } from "validation/schemas/publication";

import { apiCallBegan } from "./apiActions";
import {
  createEntitySlice,
  loadEntities,
  openEntity,
  closeEntity,
  deleteEntity,
  syncEntity,
  EntityState,
  LoadEntitiesParameters,
} from "./entity";
// import { loadPublications } from "./publications";

// import { sleep } from "utils/sleep";

export const idAttribute = "message_id_k";

const url = "/messages";
const name = "messages";

const defaultMessage = {
  message_name: "",
};

const initialState: EntityState<Message> = {
  synchronizing: false,
  list: [],
  idAttribute,
  loading: false,
  data: {},
};

// Create Slice
const slice = createEntitySlice({ name, initialState, reducers: {} });

// Export Reducer and Actions
export default slice.reducer;
export const { actions } = slice;

// Action Creators
export const loadMessages = (params?: LoadEntitiesParameters["params"]) =>
  loadEntities({ url, params, slice });

export const deleteMessage = (id: string) => deleteEntity({ url, id, slice });

export const changeMessageName = (id: string, message_name: string) => {
  const method = "patch";
  const patchUrl = `${url}/${id}`;

  return apiCallBegan({
    url: patchUrl,
    method,
    data: { message_name },
    onSuccess: slice.actions.updated.type,
  });
};

export const monitorMessage = (data: Message) => {
  const { message_status, message_id_k } = data;
  if (message_status !== "creating") return;

  // slice.

  return syncEntity({
    url,
    id: message_id_k,
    slice,
    monitors: [monitorMessage],
  });
};

export const monitorPublishedMessage = (data: Publication) => {
  // A diferencia de monitorMessage, esta función inicia el ciclo de
  // verificaciones después de subir un mensaje para publicarlo directo
  // en un spot. Después de leer el message la primera vez se continuará
  // haciendo el polling si el mensaje aún tiene estado "creating".
  const { message_id_f } = data as Publication;

  return syncEntity({
    url,
    id: message_id_f,
    slice,
    monitors: [monitorMessage],
  });
};

export const syncMessage = (data: Publication | Message) => {
  const { message_id_f } = data as Publication;
  const { message_id_k } = data as Message;
  const id = message_id_f || message_id_k;

  return syncEntity({ url, id, slice });
};

export const newMessage = () =>
  openEntity({ data: { ...defaultMessage }, slice });

export const closeMessage = () => closeEntity({ slice });

export const addMessage = (data: { [key: string]: any }) => {
  const formData = new FormData();
  const method = "post";

  // Add fields
  for (const key of Object.keys(data)) {
    if (key === "file") {
      formData.append(key, data[key]);
    } else {
      formData.set(key, data[key]);
    }
  }

  const onStart = slice.actions.requested.type;
  const onSuccess = slice.actions.added.type;
  const onError = slice.actions.requestFailed.type;
  const onProgress = slice.actions.uploadProgress.type;

  return apiCallBegan({
    url,
    method,
    data: formData,
    onStart,
    onProgress,
    onSuccess,
    onError,
    monitors: [monitorMessage],
  });
};

// export const saveMessage = (data: SaveEntityParameters["data"]) =>
//   saveEntity({ url, idAttribute, data, slice });

export const openMessage = (data: { [key: string]: any }) =>
  openEntity({ data, slice });

export const creators = {
  loadMessages,
  addMessage,
  deleteMessage,
  openMessage,
  closeMessage,
  newMessage,
};

export const getMessages = createSelector(
  (state: { [key: string]: any }) => state.entities.messages,
  (messages) => messages
);
