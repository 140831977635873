import React from "react";
import { css } from "@emotion/react";
import PacmanLoader from "react-spinners/PacmanLoader";
import { Fade } from "reactstrap";

const logoColor = "#1d8cf8";
// DEMO PAGE https://www.davidhu.io/react-spinners/

const override = css`
  display: block;
  margin: 0 auto;
  border-color: ${logoColor};
`;

export interface LoadingProps {
  loading: boolean;
  size?: number;
  color?: string;
  margin?: number;
  className?: string;
  style?: React.CSSProperties;
}

const defaultProps: LoadingProps = {
  loading: true,
  size: 25,
  color: logoColor,
};

const Loading: React.FC<LoadingProps> = ({
  size,
  color,
  loading,
  className,
  style,
}) => (
  <Fade>
    {loading && (
      <div className={className} style={style}>
        <PacmanLoader
          css={override}
          size={size}
          color={color}
          loading={loading}
        />
      </div>
    )}
  </Fade>
);

Loading.defaultProps = defaultProps;

export default Loading;
