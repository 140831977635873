import { createSlice, createSelector } from "@reduxjs/toolkit";
// import jwtDecode from 'jwt-decode';

// import { setJwt, removeJwt, getStoredJwt } from './middleware/api';
import { apiCallBegan } from "./apiActions";
// import settings from '../config/settings';

// const { oauth } = settings;

export interface AuthState {
  authorizing: boolean;
  loading: boolean;
  user?: { [key: string]: any };
  isNew: boolean;
  isActivated: boolean;
  error?: any;
  sesionStart?: number;
}

const initialState: AuthState = {
  authorizing: true,
  loading: false,
  isNew: false,
  isActivated: false,
};
const loginUrl = "/login";
const logoutUrl = "/logout";
const registerUrl = "/register";
const resetPasswordUrl = "/reset/request";
const addressUrl = "/accounts/address";
const meUrl = "/me";
const name = "auth";

const getUser = (data: {
  [key: string]: any;
}): { [key: string]: any } | undefined => {
  if (!data) return;
  const avatar_url = data.avatar_url ?? "default.png";
  return { ...data, avatar_url };
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    requestSent: (state) => {
      state.authorizing = true;
      state.loading = true;
    },
    requestFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.authorizing = false;
    },
    userLoaded: (state, action) => {
      delete state.error;
      const user = getUser(action.payload);
      state.user = user;
      state.isNew = user ? user.is_new : true;
      state.isActivated = user ? user.is_activated : false;
      state.loading = false;
      state.authorizing = false;
    },
    loggedIn: (state, action) => {
      delete state.error;
      const user = getUser(action.payload);
      state.user = user;
      state.isNew = user ? user.is_new : true;
      state.isActivated = user ? user.is_activated : false;
      //   state.isAdmin = user ? user.is_admin : false;
      state.loading = false;
      state.authorizing = false;
    },
    addressUpdated: (state, action) => {
      delete state.error;
      if (state.user) {
        state.user.address_line1 = action.payload.address_line1;
        state.user.address_line2 = action.payload.address_line2;
        state.user.city = action.payload.city;
        state.user.state = action.payload.state;
        state.user.zip = action.payload.zip;
        state.user.country_id_f = action.payload.country_id_f;
      }
      state.loading = false;
      state.authorizing = false;
    },
    setupDone: (state) => {
      // console.log('setupDone', action.payload);
      state.isNew = false;
      state.loading = false;
      state.authorizing = false;
    },
    errorCleared: (state) => {
      delete state.error;
    },
    loggedOut: (state) => {
      delete state.error;
      // delete state.user;
      state.user = undefined;
      state.loading = false;
      state.authorizing = false;
    },
  },
});

// Export Reducer and Actions
export default slice.reducer;
export const { actions } = slice;

// Action Creators
export const login = (data: any) =>
  apiCallBegan({
    url: loginUrl,
    method: "post",
    data,
    onStart: actions.requestSent.type,
    onSuccess: actions.loggedIn.type,
    onError: actions.requestFailed.type,
  });

export const register = (data: any) =>
  apiCallBegan({
    url: registerUrl,
    method: "post",
    data,
    onStart: actions.requestSent.type,
    onSuccess: actions.loggedIn.type,
    onError: actions.requestFailed.type,
  });

export const requestPasswordReset = (data: any) =>
  apiCallBegan({
    url: resetPasswordUrl,
    method: "post",
    data,
    onStart: actions.requestSent.type,
    onSuccess: actions.loggedOut.type, // TODO: set a new state.
    onError: actions.requestFailed.type,
  });

// export const logout = () => actions.loggedOut();
export const logout = () =>
  apiCallBegan({
    url: logoutUrl,
    method: "post",
    onStart: actions.requestSent.type,
    onSuccess: actions.loggedOut.type,
    onError: actions.requestFailed.type,
  });

export const loadUser = () =>
  apiCallBegan({
    url: meUrl,
    onStart: actions.requestSent.type,
    onSuccess: actions.userLoaded.type,
    onError: actions.requestFailed.type,
  });

// Action Creators
export const setup = (values: { [key: string]: any }) => {
  const method = "post";
  const url = "/setup";
  const data = new FormData();
  const { chatbot, application } = values;

  // Add chatbot fields
  for (const key of Object.keys(chatbot.data)) {
    if (key === "key_file") {
      data.append(key, chatbot.data[key]);
    } else {
      data.set(key, chatbot.data[key]);
    }
  }

  // Add application fields
  for (const key of Object.keys(application.data)) {
    data.set(key, application.data[key]);
  }
  // // console.log('data', data);

  // // console.log('createMessage data:', data);
  return apiCallBegan({
    url,
    method,
    data,
    onStart: actions.requestSent.type,
    onSuccess: actions.setupDone.type,
    onError: actions.requestFailed.type,
  });
};

export const updateAddress = (data: any) =>
  apiCallBegan({
    url: addressUrl,
    method: "put",
    data,
    onStart: actions.requestSent.type,
    onSuccess: actions.addressUpdated.type,
    onError: actions.requestFailed.type,
  });

export const clearError = () => actions.errorCleared();

export const creators = {
  login,
  register,
  requestPasswordReset,
  logout,
  clearError,
  loadUser,
  updateAddress,
};

export const accessAllowed = (
  allowedRoles: string[] | undefined,
  userRoles: string[] | undefined
) => {
  //   console.log("accessAllowed", { allowedRoles, userRoles });

  // There are not allowed roles defined, allow access.
  if (allowedRoles === undefined) return true;

  // There are allowed roles but no user roles, denied access.
  if (userRoles === undefined) return false;

  for (const role of allowedRoles) {
    if (userRoles.includes(role)) return true;
  }
  return false;
};

// Create Selectors (Memoization)
export const selectCurrentUser = createSelector(
  (state: { [key: string]: any }) => state.auth,
  (auth) => auth.user
);

export const selectUserRoles = createSelector(
  (state: { [key: string]: any }) => state.auth,
  (auth) => auth.user.roles
);

export const selectUserTimezone = createSelector(
  (state: { [key: string]: any }) => state.auth,
  (auth) => auth.user.timezone
);

export const isNewUser = createSelector(
  (state: { [key: string]: any }) => state.auth,
  (auth) => auth.isNew
);

export const isAdmin = createSelector(
  (state: { [key: string]: any }) => state.auth,
  (auth) => auth.isAdmin
);

export const isManager = createSelector(
  (state: { [key: string]: any }) => state.auth,
  (auth) => {
    const { user } = auth;
    if (!user) return false;

    const { roles } = user;
    if (!roles || !Array.isArray(roles) || roles.length === 0) return false;

    return roles.includes("Managers");
  }
);

export const isActivated = createSelector(
  (state: { [key: string]: any }) => state.auth,
  (auth) => auth.isActivated
);

export const getAuthorizing = createSelector(
  (state: { [key: string]: any }) => state.auth,
  (auth) => auth.authorizing
);
