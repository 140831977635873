import { combineReducers } from "redux";
import spotClusters from "./spotClusters";
import displayGroups from "./displayGroups";
import displays from "./displays";
import publications from "./publications";
import messages from "./messages";

export default combineReducers({
  displays,
  spotClusters,
  displayGroups,
  publications,
  messages,
});
