import React from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const logoColor = "#50a2f7";
// DEMO PAGE https://www.davidhu.io/react-spinners/

const override = css`
  display: block;
  margin: 0 auto;
  border-color: ${logoColor};
`;

export interface SpinnerProps {
  loading: boolean;
  size?: number;
  color?: string;
}

const defaultProps: SpinnerProps = {
  loading: true,
  size: 150,
  color: logoColor,
};

const Spinner: React.FC<SpinnerProps> = (props) => (
  <ClipLoader
    css={override}
    size={props.size}
    color={props.color}
    loading={props.loading}
  />
);

Spinner.defaultProps = defaultProps;

export default Spinner;
