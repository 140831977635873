/* eslint react/no-array-index-key: 0 */
import React from "react";
import i18n from "i18next";
import {
  FormGroup,
  Label,
  FormFeedback,
  Input,
  InputProps,
  FormText,
} from "reactstrap";

export interface AppSelectOption {
  value: string;
  label: string;
}

export interface AppSelectProps extends InputProps {
  label?: string;
  error?: string;
  help?: string;
  options: AppSelectOption[];
  focus?: boolean;
}

const inputStyle: React.CSSProperties = {
  padding: "6px 10px",
};

export const AppSelect: React.FC<AppSelectProps> = ({
  className,
  name,
  label,
  value,
  error,
  help,
  focus,
  options,
  ...rest
}) => {
  const invalid = error !== undefined;
  const inputRef = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    if (!inputRef) return;
    if (focus) inputRef.current?.focus();
  }, [focus, inputRef]);

  return (
    <FormGroup className={className}>
      {label && <Label for={name}>{i18n.t(label)}</Label>}
      <Input
        innerRef={inputRef}
        invalid={invalid}
        value={value}
        name={name}
        id={name}
        type="select"
        style={inputStyle}
        {...rest}
      >
        {options &&
          options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
      </Input>
      {error && <FormFeedback>{error}</FormFeedback>}
      {help && <FormText>{help}</FormText>}
    </FormGroup>
  );
};

export default AppSelect;
