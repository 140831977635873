/* eslint-disable react/jsx-boolean-value */
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import history from "appHistory";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Loading } from "components/forms";
import { loadUser } from "store/auth";
import { loadPreferences } from "store/ui";
import Privacy from "views/pages/Privacy";
import { RootState } from "store";
// import { loadDisplayGroups } from "store/displayGroups";

// Use useHistory() hook to get the history object that
// From : https://blog.logrocket.com/using-hooks-with-react-router/
// The useHistory Hooks return the history object, which the router uses internally to handle the route changes.
// The history object is used for managing session history.
// ROUTER DEBUGGING
// history.listen((location, action) => {
//   console.log(
//     `The current URL is ${location.pathname}${location.search}${location.hash}`
//   );
//   console.log(
//     `The last navigation action was ${action}`,
//     JSON.stringify(history, null, 2)
//   );
// });

const AdminLayout = React.lazy(() => import("layouts/AdminTim/LayoutFC"));
// const SetupWizard = React.lazy(() => import("views/forms/SetupWizard"));
const Login = React.lazy(() => import("views/pages/Login"));
const ForgotPassword = React.lazy(() => import("views/pages/ForgotPassword"));
const PasswordResetSent = React.lazy(
  () => import("views/pages/PasswordResetSent")
);
const Register = React.lazy(() => import("views/pages/Register"));
const Activate = React.lazy(() => import("views/pages/Activate"));
const Page404 = React.lazy(() => import("views/pages/Page404"));
const Page500 = React.lazy(() => import("views/pages/Page500"));

const App: React.FC = () => {
  const dispatch = useDispatch();

  const { user, authorizing, isActivated } = useSelector((state: RootState) => {
    const { auth } = state;
    return {
      user: auth.user,
      isNew: auth.isNew,
      isActivated: auth.isActivated,
      authorizing: auth.authorizing,
    };
  });

  useEffect(() => {
    const ac = new AbortController();

    dispatch(loadUser());
    dispatch(loadPreferences());

    return () => ac.abort(); // Abort on unmount
  }, [dispatch]);

  const loadingStyle: React.CSSProperties = {
    marginTop: "3.0rem",
  };

  // console.log({ isNew, isActivated });
  //  history={history}
  return (
    <BrowserRouter>
      <ToastContainer />
      <React.Suspense fallback={<Loading loading style={loadingStyle} />}>
        <Switch>
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route
            path="/register"
            render={(props) => <Register {...props} />}
          />{" "}
          <Route
            path="/register/:code"
            render={(props) => <Register {...props} />}
          />
          <Route
            path="/password_reset"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            path="/password_reset_sent"
            render={(props) => <PasswordResetSent {...props} />}
          />
          {/* <Route path="/setup" render={(props) => <SetupWizard {...props} />} /> */}
          <Route path="/activate" render={(props) => <Activate {...props} />} />
          <Route path="/privacy" render={(props) => <Privacy {...props} />} />
          <Route path="/404" render={(props) => <Page404 {...props} />} />
          <Route path="/500" render={(props) => <Page500 {...props} />} />
          <Route
            path="/console"
            render={(props) => {
              if (authorizing) return <Loading loading style={loadingStyle} />;

              // Denied access to console to unauthenticated users.
              if (!user) return <Redirect to="/login" />;

              if (!isActivated) return <Redirect to="/activate" />;
              // if (isNew) return <Redirect to="/setup" />;
              return <AdminLayout {...props} />;
            }}
          />
          <Route path="/">
            <Redirect to="/console" />
          </Route>
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
