import { createSelector, PayloadAction } from "@reduxjs/toolkit";

import { SpotCluster } from "validation/schemas/spotCluster";
import { Publication } from "validation/schemas/publication";
// import { Message } from "validation/schemas/message";

import { syncMessage, monitorPublishedMessage } from "./messages";

import { apiCallBegan } from "./apiActions";
import {
  createEntitySlice,
  loadEntities,
  saveEntity,
  openEntity,
  closeEntity,
  deleteEntity,
  EntityState,
  LoadEntitiesParameters,
  SaveEntityParameters,
  syncEntity,
} from "./entity";

// import { updatePublishingPage } from "./ui";

export const idAttribute = "publication_id_k";

const url = "/publications";
const publicationsEndpoint = "/spot-clusters-publications";
const name = "publications";

const initialState: EntityState<SpotCluster> = {
  synchronizing: false,
  list: [],
  idAttribute,
  loading: false,
  data: {},
};

const addPublication = (
  state: EntityState<SpotCluster>,
  action: PayloadAction<Publication>
) => {
  const { spot_cluster_id_f, spot_id_f } = action.payload;

  const spotCluster = state.list.find(
    ({ spot_cluster_id_k }) => spot_cluster_id_k === spot_cluster_id_f
  );

  if (spotCluster) {
    const { spots } = spotCluster;
    const spot = spots.find(({ spot_id_k }) => spot_id_k === spot_id_f);

    if (spot) spot.publication = action.payload;
  }

  // Close current entity.
  state.current = undefined;
  state.synchronizing = false;
  state.progress = undefined;
};

const updatePublication = (
  state: EntityState<SpotCluster>,
  action: PayloadAction<Publication>,
  synchronized = false
) => {
  const { spot_cluster_id_f, spot_id_f } = action.payload;

  const spotCluster = state.list.find(
    ({ spot_cluster_id_k }) => spot_cluster_id_k === spot_cluster_id_f
  );

  if (spotCluster) {
    const { spots } = spotCluster;
    const spot = spots.find(({ spot_id_k }) => spot_id_k === spot_id_f);

    if (spot) spot.publication = action.payload;
  }

  // Cuando se ha sincronizado, que es "actualizado con lo que está en el servidor",
  // no es necesario hacer nada con el estado. Solo cambiamos el current, progress
  // y synchronizing cuando se trata de un update (PUT) llamado por el usuario.
  if (synchronized) return;

  // Close current entity.
  state.current = undefined;
  state.synchronizing = false;
  state.progress = undefined;
};

// Create Slice
const slice = createEntitySlice({
  name,
  initialState,
  reducers: {
    added: (
      state: EntityState<SpotCluster>,
      action: PayloadAction<Publication>
    ) => {
      addPublication(state, action);
    },

    updated: (
      state: EntityState<SpotCluster>,
      action: PayloadAction<Publication>
    ) => {
      updatePublication(state, action);
    },

    synchronized: (
      state: EntityState<SpotCluster>,
      action: PayloadAction<Publication>
    ) => {
      updatePublication(state, action, true);
    },

    removed: (
      state: EntityState<SpotCluster>,
      action: PayloadAction<{ [key: string]: any }>
    ) => {
      const { spot_cluster_id_f, spot_id_f, publication_id_k } = action.payload;
      const spotCluster = state.list.find(
        ({ spot_cluster_id_k }) => spot_cluster_id_k === spot_cluster_id_f
      );
      if (spotCluster) {
        const spots = spotCluster.spots as Record<string, any>[];
        const spot = spots.find(({ spot_id_k }) => spot_id_k === spot_id_f);

        if (spot) {
          const { publication } = spot;
          if (
            publication &&
            publication.publication_id_k &&
            publication.publication_id_k === publication_id_k
          )
            spot.publication = {};
        }
      }
      state.synchronizing = false;
    },
  },
});

// Export Reducer and Actions
export default slice.reducer;
export const { actions } = slice;

// Action Creators
export const loadPublications = (params?: LoadEntitiesParameters["params"]) => {
  const id = undefined;
  const requestUrl = `${publicationsEndpoint}${id ? `/${id}` : ""}`;
  // El trigger no funciona en este caso porque el payload utilizado es
  // lo que devuelve el API (server), no el filtro utilizado para hacer
  // el request.
  // const trigger = updatePublishingPage;
  return loadEntities({ url: requestUrl, params, slice });
};

export const deletePublication = (id: string) =>
  deleteEntity({
    url,
    id,
    slice,
    trigger: syncMessage,
  });

export const newPublication = (spot: Record<string, any>) =>
  openEntity({ data: { ...spot }, slice });

export const closePublication = () => closeEntity({ slice });

export const monitorPublication = (data: Publication) => {
  //   console.log("monitorPublication", { data });
  const { publication_id_k, message_status } = data;
  if (message_status !== "creating") return;

  return syncEntity({
    url,
    id: publication_id_k,
    slice,
    monitors: [monitorPublication],
  });
};

export const publishMessage = (data: Record<string, any>) => {
  const { message_id_f: messageId, spot_id_f: spotId } = data;
  //   console.log(JSON.stringify(data, null, 2));
  const requestUrl = encodeURI(`${url}/${messageId}/publish/${spotId}`);
  const method = "post";

  const onStart = slice.actions.requested.type;
  const onSuccess = slice.actions.added.type;
  const onError = slice.actions.requestFailed.type;
  const trigger = syncMessage;

  return apiCallBegan({
    url: requestUrl,
    method,
    data,
    onStart,
    onSuccess,
    onError,
    trigger,
  });
};

export const publishMedia = (spotId: string, data: { [key: string]: any }) => {
  const requestUrl = `${url}/${spotId}`;
  const formData = new FormData();
  const method = "post";

  // Add fields
  for (const key of Object.keys(data)) {
    if (key === "file") {
      formData.append(key, data[key]);
    } else {
      formData.set(key, data[key]);
    }
  }

  const onStart = slice.actions.requested.type;
  const onSuccess = slice.actions.added.type;
  const onError = slice.actions.requestFailed.type;
  const onProgress = slice.actions.uploadProgress.type;

  return apiCallBegan({
    url: requestUrl,
    method,
    data: formData,
    onStart,
    onProgress,
    onSuccess,
    onError,
    monitors: [monitorPublishedMessage, monitorPublication],
  });
};

export const savePublication = (data: SaveEntityParameters["data"]) =>
  saveEntity({ url, idAttribute, data, slice, trigger: syncMessage });

export const openPublication = (data: { [key: string]: any }) =>
  openEntity({ data, slice });

// export const publishMessage = (data: Record<string, any>) => {
//     const { message_id_f: messageId, spot_id_f: spotId } = data;
//     console.log(JSON.stringify(data, null, 2));

//     const url = `${endpoint}/${messageId}/publish/${spotId}`;

//     return post(url, data);
//   };

export const creators = {
  loadPublications,
  savePublication,
  deletePublication,
  //   newPublication,
  openPublication,
  closePublication,
};

export const getPublications = createSelector(
  (state: { [key: string]: any }) => state.entities.publications,
  (publications) => publications as EntityState<SpotCluster>
);

// export const getPublicationsOptions = createSelector(
//   (state: { [key: string]: any }) => state.entities.publications,
//   ({ list }) =>
//     list.map((display: { [key: string]: string }) => ({
//       value: display[idAttribute],
//       label: display[nameAttribute],
//     }))
// );
