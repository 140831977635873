import React from "react";
import {
  confirmable,
  ReactConfirmProps,
  createConfirmation,
} from "react-confirm";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  Button,
} from "reactstrap";
import i18n from "i18next";
import { isMobile } from "react-device-detect";

export interface AppConfirmProps {
  show: boolean;
  proceed: (value: boolean) => void;
  confirmation: string;
  options: { [key: string]: any };
  title: string;
}

const AppConfirmDialog: React.FC<ReactConfirmProps & AppConfirmProps> = ({
  show,
  proceed,
  confirmation,
  title = "Confirm",
}) => (
  <Modal isOpen={show} toggle={() => proceed(false)} autoFocus={false}>
    <ModalHeader toggle={() => proceed(false)}>{title}</ModalHeader>
    <ModalBody>
      <Row form>
        <Col md={12}>{confirmation}</Col>
      </Row>
    </ModalBody>
    <ModalFooter>
      <Row form style={styles.footer}>
        <Col md={6} xs={12}>
          <></>
        </Col>
        <Col md={3} xs={12} style={styles.yesButtonContainer}>
          <Button
            block={isMobile}
            color="danger"
            className="btn-round"
            style={styles.button}
            onClick={() => proceed(true)}
          >
            {i18n.t("button.yes")}
          </Button>
        </Col>
        <Col md={3} xs={12} style={styles.noButtonContainer}>
          <Button
            block={isMobile}
            // color="default"
            className="btn-round btn-simple"
            style={styles.button}
            onClick={() => proceed(false)}
          >
            {i18n.t("button.no")}
          </Button>
        </Col>
      </Row>
    </ModalFooter>
  </Modal>
);

const confirmableDialog = confirmable(AppConfirmDialog);

export const confirm = createConfirmation(confirmableDialog);

export default confirm;

const styles = {
  footer: {
    width: "100%",
    padding: 0,
    margin: 0,
  },
  button: {
    width: "100%",
  },
  yesButtonContainer: {
    paddingLeft: isMobile ? 0 : 5,
    paddingRight: isMobile ? 0 : 2,
    marginBottom: "1.5em",
  },
  noButtonContainer: {
    paddingLeft: isMobile ? 0 : 8,
    paddingRight: isMobile ? 0 : 0,
    marginBottom: "1.5em",
  },
};
