/* eslint no-param-reassign: 0 */
// import { State } from "redux";
import {
  createSlice,
  Slice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";

// import settings from "config/settings";
import { apiCallBegan, ApiCallBeganActionPayload } from "./apiActions";

// const { monitorTimeout } = settings;

export interface UploadProgress {
  loaded: number;
  total: number;
}

export interface EntityMonitor<T = Record<string, any>> {
  timestamp: number;
  entity: T;
}

// loading is used to know the status of a list request (search).
// synchronizing is used to know the status of any other command.

export interface EntityState<T = Record<string, any>> {
  synchronizing: boolean;
  list: T[];
  monitor?: EntityMonitor<T>[];
  filter?: Record<string, any>;
  idAttribute: string;
  nameAttribute?: string;
  progress?: UploadProgress;
  error?: string;
  current?: T;
  lastFetch?: number;
  loading: boolean;
  data: Record<string, any>;
}

export const defaultInitialState: EntityState<any> = {
  synchronizing: false,
  list: [],
  idAttribute: "id",
  nameAttribute: "",
  loading: false,
  data: {},
};

export const addEntity = <T>(
  state: EntityState<T>,
  action: PayloadAction<T>
) => {
  // Add new entity to the beginning of the array.
  state.list.unshift(action.payload);

  // Close current entity.
  state.current = undefined;
  state.synchronizing = false;
  state.progress = undefined;
  state.error = undefined;
};

export const updateEntity = <T>(
  state: EntityState<T>,
  action: PayloadAction<T & { [key: string]: any }>,
  synchronized = false
) => {
  const { idAttribute } = state;
  const { [idAttribute]: id } = action.payload;

  // Only update (synchronize) the entity on the list.
  state.list = state.list.map((obj: T & { [key: string]: any }) =>
    obj[idAttribute] === id ? action.payload : obj
  );

  // Cuando se ha sincronizado, que es "actualizado con lo que está en el servidor",
  // no es necesario hacer nada con el estado. Solo cambiamos el current, progress
  // y synchronizing cuando se trata de un update (PUT) llamado por el usuario.
  if (synchronized) return;

  // Close current entity.
  state.current = undefined;
  state.synchronizing = false;
  state.progress = undefined;
  state.error = undefined;
};

export const createEntitySlice = <
  T,
  Reducers extends SliceCaseReducers<EntityState<T>>
>({
  name,
  initialState,
  reducers,
}: {
  name: string;
  initialState: EntityState<T>;
  reducers: ValidateSliceCaseReducers<EntityState<T>, Reducers>;
}) =>
  createSlice({
    name,
    initialState,
    reducers: {
      listCleared: (state: EntityState<T>) => {
        state.list = [];
        state.synchronizing = true;
      },
      listRequested: (state: EntityState<T>) => {
        state.loading = true;
      },
      requested: (state: EntityState<T>) => {
        state.synchronizing = true;
      },
      uploadProgress: (
        state: EntityState<T>,
        action: PayloadAction<UploadProgress>
      ) => {
        state.progress = action.payload;
      },
      requestSucceeded: (state: EntityState<T>) => {
        state.synchronizing = false;
        state.progress = undefined;
        state.error = undefined;
      },
      requestFailed: (state: EntityState<T>, action: PayloadAction<string>) => {
        state.synchronizing = false;
        state.loading = false;
        state.progress = undefined;
        state.error = action.payload;
      },
      got: (state: EntityState<T>, action: PayloadAction<T>) => {
        state.synchronizing = false;
        state.progress = undefined;
        state.current = action.payload;
        state.error = undefined;
      },
      loaded: (state: EntityState<T>, action: PayloadAction<T[]>) => {
        // state.synchronizing = false;
        state.loading = false;
        state.progress = undefined;
        state.list = action.payload;
        state.error = undefined;
      },
      added: (state: EntityState<T>, action: PayloadAction<T>) => {
        addEntity<T>(state, action);
      },
      updated: (state: EntityState<T>, action: PayloadAction<T>) => {
        updateEntity<T>(state, action);
      },
      synchronized: (state: EntityState<T>, action: PayloadAction<T>) => {
        updateEntity<T>(state, action, true);
      },
      removed: (
        state: EntityState<T>,
        action: PayloadAction<{ [key: string]: any }>
      ) => {
        // const
        const { idAttribute } = state;
        const { [idAttribute]: id } = action.payload;
        state.list = state.list.filter(
          (obj: { [key: string]: any }) => obj[idAttribute] !== id
        );
        state.synchronizing = false;
        state.progress = undefined;
      },
      opened: (state: EntityState<T>, action: PayloadAction<T>) => {
        state.current = action.payload;
        // Make sure progress is reset when openning an item.
        state.progress = undefined;
      },
      dataChanged: (
        state: EntityState<T>,
        action: PayloadAction<Record<string, any>>
      ) => {
        state.data = action.payload;
      },
      closed: (state: EntityState<T>) => {
        state.current = undefined;
        // Make sure progress is reset after closing an item.
        state.progress = undefined;
      },
      ...reducers,
    },
  });

// Action Creators
export interface LoadEntitiesParameters extends ApiCallBeganActionPayload {
  url: ApiCallBeganActionPayload["url"];
  params?: ApiCallBeganActionPayload["params"];
  slice: Slice;
}

export const getEntity = ({
  url,
  slice,
  onStart = slice.actions.requested.type,
  onSuccess = slice.actions.got.type,
  onError = slice.actions.requestFailed.type,
  ...rest
}: LoadEntitiesParameters) => {
  url = encodeURI(url);

  return apiCallBegan({
    url,
    onStart,
    onSuccess,
    onError,
    ...rest,
  });
};

export const loadEntities = ({
  url,
  params,
  slice,
  onStart = slice.actions.listRequested.type,
  onSuccess = slice.actions.loaded.type,
  onError = slice.actions.requestFailed.type,
  ...rest
}: LoadEntitiesParameters) => {
  url = encodeURI(url);

  return apiCallBegan({
    url,
    params,
    onStart,
    onSuccess,
    onError,
    ...rest,
  });
};

export interface SaveEntityParameters extends ApiCallBeganActionPayload {
  idAttribute: string;
  slice: Slice;
}

export const getOnSuccess = (slice: Slice, id: any) =>
  id ? slice.actions.updated.type : slice.actions.added.type;

export const saveEntity = ({
  url,
  idAttribute,
  data,
  slice,
  onStart = slice.actions.requested.type,
  onSuccess,
  onError = slice.actions.requestFailed.type,
  ...rest
}: SaveEntityParameters) => {
  let method = "post";
  const id = data ? data[idAttribute] : undefined;
  if (!onSuccess) onSuccess = getOnSuccess(slice, id);

  if (id) {
    url += `/${id}`;
    method = "put";
  }

  url = encodeURI(url);
  return apiCallBegan({
    url,
    method,
    data,
    onStart,
    onSuccess,
    onError,
    ...rest,
  });
};

// export interface SaveEntityParameters extends ApiCallBeganActionPayload {
//     idAttribute: string;
//     slice: Slice;
//   }

export interface DeleteEntityParameters
  extends Omit<Omit<ApiCallBeganActionPayload, "idAttribute">, "data"> {
  url: string;
  id: string;
  slice: Slice;
}

export interface SyncEntityParameters
  extends Omit<Omit<ApiCallBeganActionPayload, "idAttribute">, "data"> {
  url: string;
  id: string;
  slice: Slice;
}

export const syncEntity = ({
  url,
  id,
  slice,
  // Durante sincronizaciones NO ponemos syncronizing en true.
  // La llamada al API para obtener la versión actual del item
  // la hacemos en modo MUTE.
  // onStart = slice.actions.requested.type,
  onStart,
  onSuccess = slice.actions.synchronized.type,
  onError = slice.actions.requestFailed.type,
  ...rest
}: SyncEntityParameters) => {
  const method = "get";
  url += `/${id}`;
  url = encodeURI(url);

  return apiCallBegan({
    url,
    method,
    onStart,
    onSuccess,
    onError,
    ...rest,
  });
};

export const deleteEntity = ({
  url,
  id,
  slice,
  onStart = slice.actions.requested.type,
  onSuccess = slice.actions.removed.type,
  onError = slice.actions.requestFailed.type,
  ...rest
}: DeleteEntityParameters) => {
  const method = "delete";
  url += `/${id}`;
  url = encodeURI(url);

  return apiCallBegan({
    url,
    method,
    onStart,
    onSuccess,
    onError,
    ...rest,
  });
};

export interface OpenEntityParameters {
  data: { [key: string]: any };
  slice: Slice;
}

export const openEntity = ({ data, slice }: OpenEntityParameters) =>
  slice.actions.opened(data);

export interface CloseEntityParameters {
  slice: Slice;
}

export const closeEntity = ({ slice }: CloseEntityParameters) =>
  slice.actions.closed({});
