import { createAction } from "@reduxjs/toolkit";
import { Action } from "redux";

export const API_CALL_BEGAN = "api/callBegan";
export const API_CALL_SUCCEEDED = "api/callSucceeded";
export const API_CALL_FAILED = "api/callFailed";

export type ApiCallBeganType = "api/callBegan";

export interface ApiCallActionCreator {
  payload: ApiCallBeganActionPayload;
  type: string;
}

export type MonitorFunction = (
  payload: any
) => ApiCallActionCreator | undefined;

export interface ApiCallBeganActionPayload {
  url: string;
  params?: { [key: string]: any };
  method?: string;
  data?: any; // { [key: string]: any };
  trigger?: (payload: any) => ApiCallActionCreator;
  monitors?: MonitorFunction[];
  onSuccess?: string | string[];
  onError?: string;
  onStart?: string;
  onProgress?: string;
}

export interface ApiCallBeganAction extends Action {
  type: typeof API_CALL_BEGAN;
  payload: ApiCallBeganActionPayload;
}

export interface ApiCallSucceededAction extends Action {
  type: typeof API_CALL_SUCCEEDED;
  payload: { [key: string]: any } | any[];
}

export interface ApiCallFailedAction extends Action {
  type: typeof API_CALL_FAILED;
  payload: Error;
}

export type ApiActionTypes =
  | ApiCallBeganAction
  | ApiCallSucceededAction
  | ApiCallFailedAction;

export const apiCallBegan =
  createAction<ApiCallBeganActionPayload>(API_CALL_BEGAN);

export const apiCallSuccess = createAction<{ [key: string]: any } | any[]>(
  API_CALL_SUCCEEDED
);

export const apiCallFailed = createAction<string>(API_CALL_FAILED);

export default {
  apiCallBegan,
  apiCallSuccess,
  apiCallFailed,
};
