import { Middleware } from "redux";

const logger: Middleware = () => (next) => async (action) => next(action);
export default logger;

// FOR DEBUGGING
// const logger: Middleware = (store) => (next) => async (action) => {
//   console.log("store:", store);
//   console.log("next:", next);
//   console.log("action:", action);
//   return next(action);
// };
