import { createSelector } from "@reduxjs/toolkit";
import {
  createEntitySlice,
  loadEntities,
  saveEntity,
  openEntity,
  closeEntity,
  deleteEntity,
  EntityState,
  LoadEntitiesParameters,
  SaveEntityParameters,
} from "./entity";

export const idAttribute = "spot_cluster_id_k";

export const defaultSpotCluster = {
  spot_cluster_name: "",
  spot_duration: 15,
  spot_duration_type: "flexible",
  low_priority_cycles: 0,
  normal_priority_cycles: 1,
  spots_per_normal_priority_cycle: 40,
  spots_per_low_priority_cycle: 0,
  dayparting: "always",
  period: "daily",
  override: false,
  every: [],
  show_on: [],
  message_profile_id_f: null,
  //   from_date_time: "2021-08-05T15:14:16",
};

// THIS IS THE SLICE NAME.
const name = "spot-clusters";

const url = "/spot-clusters";
const nameAttribute = "spot_cluster_name";

export interface SpotClusterData {
  refSpotCluster?: { [key: string]: any };
}

const initialState: EntityState<SpotClusterData> = {
  synchronizing: false,
  list: [],
  idAttribute,
  nameAttribute,
  loading: false,
  data: {
    refSpotCluster: undefined,
  },
};

// Create Slice
const slice = createEntitySlice({ name, initialState, reducers: {} });

// Export Reducer and Actions
export default slice.reducer;
export const { actions } = slice;

// Action Creators
export const loadSpotClusters = (params?: LoadEntitiesParameters["params"]) =>
  loadEntities({ url, params, slice });

export const saveSpotCluster = (data: SaveEntityParameters["data"]) =>
  saveEntity({ url, idAttribute, data, slice });

export const deleteSpotCluster = (id: string) =>
  deleteEntity({ url, id, slice });

export const newSpotCluster = () =>
  openEntity({ data: { ...defaultSpotCluster }, slice });

export const openSpotCluster = (data: { [key: string]: any }) =>
  openEntity({ data, slice });

export const closeSpotCluster = () => closeEntity({ slice });

export const creators = {
  loadSpotClusters,
  saveSpotCluster,
  deleteSpotCluster,
  newSpotCluster,
  openSpotCluster,
  closeSpotCluster,
};

export const getSpotClusters = createSelector(
  (state: { [key: string]: any }) => state.entities.spotClusters,
  (entities) => entities
);

export const getSpotClustersList = createSelector(
  (state: { [key: string]: any }) => state.entities.spotClusters,
  ({ list }) => list
);

export const getSpotClustersOptions = createSelector(
  (state: { [key: string]: any }) => state.entities.spotClusters,
  ({ list }) =>
    list.map((entity: { [key: string]: string }) => ({
      value: entity[idAttribute],
      label: entity[nameAttribute],
    }))
);
