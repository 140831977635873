import "react-app-polyfill/stable";
// import 'react-app-polyfill/ie9'; // For IE 9-11 support
// import 'react-app-polyfill/ie11'; // For IE 11 support

import React from "react";
import ReactDOM from "react-dom";

// import 'assets/css/bootstrap.min.css';
import "assets/css/nucleo-icons.css";
import "assets/scss/black-dashboard-pro-react.scss?v=1.1.0";

import "./index.css";

import "react-day-picker/lib/style.css";
import "react-toastify/dist/ReactToastify.css";

// import "react-toastify/scss/main.scss";
// import { injectStyle } from "react-toastify/dist/inject-style";

/* Internationalization */
import { I18nextProvider } from "react-i18next";
import i18n from "i18n"; // Here we MUST import "i18n" module, NOT "i18next".

/* Redux */
import { Provider, useDispatch } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import configureStore from "./store";

export const store = configureStore();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be resused to resolve types

// https://fkhadra.github.io/react-toastify/how-to-style/#inject-style-on-demand
// react-toastify: CALL IT ONCE IN YOUR APP
// injectStyle();

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
