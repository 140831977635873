import { createSlice, createSelector } from "@reduxjs/toolkit";

import { apiCallBegan } from "./apiActions";

export interface SubscriptionState {
  synchronizing: boolean;
  loading: boolean;
  data: { [key: string]: any };
  active: boolean;
  error?: any;
  //   current?: { [key: string]: any };
  status: "showing" | "cancelling" | "subscribing" | "updating" | "changing";
}

const initialState: SubscriptionState = {
  synchronizing: true,
  status: "showing",
  loading: false,
  active: false,
  data: {},
};

const baseUrl = "/subscriptions";
const name = "subscription";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    requestSent: (state) => {
      state.synchronizing = true;
      state.loading = true;
    },
    requestFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.synchronizing = false;
    },
    subscriptionLoaded: (state, action) => {
      delete state.error;
      //   console.log(action.payload);
      const data = action.payload as { [key: string]: any };
      state.data = data;
      state.active = data.active ? data.active : false;
      state.loading = false;
      state.synchronizing = false;
    },
    errorCleared: (state) => {
      delete state.error;
    },
    statusChanged: (state, action) => {
      //   const { status, data } = action.payload;
      //   state.cancelling = status === "cancelling";
      //   state.subscribing = status === "subscribing";
      //   state.updating = status === "updating";
      //   state.current = data;
      const status = action.payload;
      state.status = status;
    },
    dataChanged: (state, action) => {
      state.data = action.payload;
    },
    // closed: (state) => {
    //   state.current = undefined;
    // },
  },
});

// Export Reducer and Actions
export default slice.reducer;
export const { actions } = slice;

export const subscribe = (data: Record<string, any>) =>
  apiCallBegan({
    url: baseUrl,
    method: "post",
    data,
    onStart: actions.requestSent.type,
    onSuccess: actions.subscriptionLoaded.type,
    onError: actions.requestFailed.type,
  });

export const loadSubscription = () =>
  apiCallBegan({
    url: baseUrl,
    onStart: actions.requestSent.type,
    onSuccess: actions.subscriptionLoaded.type,
    onError: actions.requestFailed.type,
  });

export const clearError = () => actions.errorCleared();

// export const cancelSubscription = (data: { [key: string]: any }) =>
//   actions.opened({ status: "cancelling", data });

// export const openSubscription = (data: { [key: string]: any }) =>
//   actions.statusChanged("subscribing");

export const startSubscription = () => actions.statusChanged("subscribing");

export const updateSubscription = () => actions.statusChanged("updating");

export const cancelSubscription = () => actions.statusChanged("cancelling");

export const changeSubscription = () => actions.statusChanged("changing");

export const closeSubscription = () => actions.statusChanged("showing");

// export const creators = {
//   clearError,
//   loadSubscription,
// };

// Create Selectors (Memoization)
export const selectSubscription = createSelector(
  (state: { [key: string]: any }) => state.subscription,
  (subscription) => subscription
);

export const selectActive = createSelector(
  (state: { [key: string]: any }) => state.subscription,
  (subscription) => subscription.active
);
