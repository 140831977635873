import axios, { AxiosRequestConfig } from "axios";
import { Middleware } from "redux";
import { toast } from "react-toastify";
import i18n from "i18next";

import logger from "store/middleware/logService";
import settings from "config/settings";
import actions, { ApiCallBeganAction } from "store/apiActions";
import { actions as authActions } from "store/auth";

const { apiUrl, pollingInterval } = settings;

// In development, we have to set withCredentials to true in order to send credentials,
// because the frontend and backend are using different ports.
// From: https://stackoverflow.com/questions/46288437/set-cookies-for-cross-origin-requests
const sendCredentials = process.env.NODE_ENV === "development";

axios.interceptors.response.use(undefined, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
    toast.error("An unexpected error occurred.", {
      position: "top-center",
    });
  }

  return Promise.reject(error);
});

export const httpGet = async (url: string, config?: AxiosRequestConfig) =>
  axios.get(url, config);

export const getBase64 = (url: string) =>
  httpGet(url, {
    responseType: "arraybuffer",
  }).then((response) =>
    Buffer.from(response.data, "binary").toString("base64")
  );

export const getImageData = async (url: string, ifNull = "") => {
  try {
    const imageData = await getBase64(url);

    if (!imageData) return ifNull;

    return `data:image/jpeg;base64,${imageData}`;
  } catch (error) {
    return "";
  }
};

export const middleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (!action) return;
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const {
      url,
      params,
      method,
      data,
      onSuccess,
      onError,
      onStart,
      onProgress,
      trigger,
      monitors,
    } = (action as ApiCallBeganAction).payload;

    if (onStart) dispatch({ type: onStart });

    next(action);

    const axiosMethod = method as AxiosRequestConfig["method"];

    const onUploadProgress = onProgress
      ? (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          dispatch({ type: onProgress, payload: { loaded, total } });
        }
      : undefined;

    try {
      const response = await axios.request({
        baseURL: apiUrl,
        url,
        method: axiosMethod,
        data,
        params,
        withCredentials: sendCredentials,
        onUploadProgress,
      });

      dispatch(actions.apiCallSuccess(response.data));

      if (onSuccess) {
        const actionTypes =
          typeof onSuccess === "string" ? [onSuccess] : onSuccess;

        for (const actionType of actionTypes) {
          dispatch({ type: actionType, payload: response.data });
        }
      }

      if (trigger) dispatch(trigger(response.data));

      // TODO: add entities monitor.
      if (monitors)
        setTimeout(() => {
          for (const monitor of monitors) {
            const actionCreator = monitor(response.data);
            if (!actionCreator) return;

            dispatch(actionCreator);
          }
        }, pollingInterval * 1000);

      //   dispatch(monitor(response.data));
    } catch (err) {
      //   const { response } = err as AxiosError;
      //   const message: string =
      //     response !== undefined && response.data
      //       ? response.data
      //       : (err as string);

      const { response, message } = err as Record<string, any>;
      const errorMessage: string = response ? i18n.t(response.data) : message;

      // TODO: Translate error message.
      // i18next.t(response.data)

      //   console.log(
      //     "Redux-API error:",
      //     JSON.stringify({ response, message }, null, 2)
      //   );

      dispatch(actions.apiCallFailed(errorMessage));
      if (onError) dispatch({ type: onError, payload: errorMessage });

      if (response !== undefined) {
        const { status } = response;

        // Session expired.
        if (status === 401)
          return dispatch({
            type: authActions.loggedOut,
            payload: errorMessage,
          });
      }

      toast.error(errorMessage, {
        position: "top-center",
      });
    }
  };

export default middleware;

// // console.log({ err });
// const { response, message } = err as Record<string, any>;
// const errorMessage: string = response ? i18n.t(response.data) : message;

// if (response !== undefined) {
//   const { status } = response;

//   // Session expired.
//   if (status === 401)
//     return dispatch({ type: actions.loggedOut, payload: errorMessage });
// }

// toast.error(errorMessage, {
//   position: "top-center",
// });
