import settings from "config/settings";
import i18n from "i18next";

export interface SelectOption {
  value: string;
  label: string;
}

// export const getLanguageOptions = (): SelectOption[] => settings.lists.languages;
// export const getVoiceGenderOptions = (): SelectOption[] => settings.lists.voiceGenders;

export const defaultBGMode = settings.defaultBGMode as string;

const osArray = settings.lists.os as SelectOption[];
export const OS_OPTIONS: SelectOption[] = osArray.map(({ value, label }) => ({
  value,
  label: i18n.t(label),
}));

const orientationArray = settings.lists.orientations as SelectOption[];
export const ORIENTATION_OPTIONS: SelectOption[] = orientationArray.map(
  ({ value, label }) => ({
    value,
    label: i18n.t(label),
  })
);

const usageArray = settings.lists.displayUsage as SelectOption[];
export const DISPLAY_USAGE_OPTIONS: SelectOption[] = usageArray.map(
  ({ value, label }) => ({
    value,
    label: i18n.t(label),
  })
);

// export const ORIENTATION_OPTIONS = settings.lists.orientations;
// export const OS_OPTIONS = settings.lists.os;
export const RESOLUTION_TOLERANCES = settings.lists.resolutionTolerances;
export const VOICE_GENDER_OPTIONS = settings.lists.voiceGenders;
export const LANGUAGE_OPTIONS = settings.lists.languages;
export const SORT_ALG_OPTIONS = settings.lists.sortAlgs;
export const APPLICATION_TARGET_OPTIONS = settings.lists.applicationTargets;

export const DAYS_OF_MONTH_OPTIONS = [
  {
    value: "1",
    label: i18n.t("label.day_1"),
  },
  {
    value: "2",
    label: i18n.t("label.day_2"),
  },
  {
    value: "3",
    label: i18n.t("label.day_3"),
  },
  {
    value: "4",
    label: i18n.t("label.day_4"),
  },
  {
    value: "5",
    label: i18n.t("label.day_5"),
  },
  {
    value: "6",
    label: i18n.t("label.day_6"),
  },
  {
    value: "7",
    label: i18n.t("label.day_7"),
  },
  {
    value: "8",
    label: i18n.t("label.day_8"),
  },
  {
    value: "9",
    label: i18n.t("label.day_9"),
  },
  {
    value: "10",
    label: i18n.t("label.day_10"),
  },
  {
    value: "11",
    label: i18n.t("label.day_11"),
  },
  {
    value: "12",
    label: i18n.t("label.day_12"),
  },
  {
    value: "13",
    label: i18n.t("label.day_13"),
  },
  {
    value: "14",
    label: i18n.t("label.day_14"),
  },
  {
    value: "15",
    label: i18n.t("label.day_15"),
  },
  {
    value: "16",
    label: i18n.t("label.day_16"),
  },
  {
    value: "17",
    label: i18n.t("label.day_17"),
  },
  {
    value: "18",
    label: i18n.t("label.day_18"),
  },
  {
    value: "19",
    label: i18n.t("label.day_19"),
  },
  {
    value: "20",
    label: i18n.t("label.day_20"),
  },
  {
    value: "21",
    label: i18n.t("label.day_21"),
  },
  {
    value: "22",
    label: i18n.t("label.day_23"),
  },
  {
    value: "23",
    label: i18n.t("label.day_23"),
  },
  {
    value: "24",
    label: i18n.t("label.day_24"),
  },
  {
    value: "25",
    label: i18n.t("label.day_25"),
  },
  {
    value: "26",
    label: i18n.t("label.day_26"),
  },
  {
    value: "27",
    label: i18n.t("label.day_27"),
  },
  {
    value: "28",
    label: i18n.t("label.day_28"),
  },
  {
    value: "29",
    label: i18n.t("label.day_29"),
  },
  {
    value: "30",
    label: i18n.t("label.day_30"),
  },
  {
    value: "31",
    label: i18n.t("label.day_31"),
  },
];

export const DAYS_OF_WEEK_OPTIONS = [
  {
    value: "1",
    label: i18n.t("label.monday"),
  },
  {
    value: "2",
    label: i18n.t("label.tuesday"),
  },
  {
    value: "3",
    label: i18n.t("label.wednesday"),
  },
  {
    value: "4",
    label: i18n.t("label.thursday"),
  },
  {
    value: "5",
    label: i18n.t("label.friday"),
  },
  {
    value: "6",
    label: i18n.t("label.saturday"),
  },
  {
    value: "7",
    label: i18n.t("label.sunday"),
  },
];
