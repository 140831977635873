import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { Row, Col } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

import settings from "config/settings";
import { AppSelect, AppInput } from "components/forms";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "store/auth";

const { clientId, prepaidOptions, currency_code, custom } = settings.paypal;
const CUSTOM_AMOUNT = "CUSTOM";
const DEFAULT_AMOUNT = prepaidOptions[0].value;

prepaidOptions.push({ value: CUSTOM_AMOUNT, text: "Custom amount" });

const getPayPalAmount = (value: string): number =>
  Math.round(parseFloat(value) * 100) / 100;

export interface AppPayPalPaymentState {
  isCustom: boolean;
  customAmount: string;
  customError?: string;
  paypalAmount: number;
  selectedAmount: string;
  alert?: any;
}

const initialState: AppPayPalPaymentState = {
  customAmount: DEFAULT_AMOUNT,
  isCustom: false,
  paypalAmount: getPayPalAmount(DEFAULT_AMOUNT),
  selectedAmount: DEFAULT_AMOUNT,
};

const AppPayPalPayment: React.FC = () => {
  const [state, setState] = useState(initialState);
  const { isCustom, customAmount, selectedAmount, customError, paypalAmount } =
    state;

  const user = useSelector(selectCurrentUser);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const newIsCustom = value === CUSTOM_AMOUNT;
    const newPaypalAmount = getPayPalAmount(
      newIsCustom ? DEFAULT_AMOUNT : value
    );
    const newCustomAmount = newIsCustom ? DEFAULT_AMOUNT : value;

    setState({
      ...state,
      selectedAmount: value,
      customAmount: newCustomAmount,
      paypalAmount: newPaypalAmount,
      isCustom: newIsCustom,
    });
  };

  const handleCustomAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('handleCustomAmount event.target', event.target);
    const { validationMessage, validity, value } = event.target;

    const newPaypalAmount = getPayPalAmount(
      validity.valid ? value : DEFAULT_AMOUNT
    );
    const newCustomError = validity.valid ? undefined : validationMessage;

    setState({
      ...state,
      customAmount: value,
      paypalAmount: newPaypalAmount,
      customError: newCustomError,
    });
  };

  const hideAlert = () => {
    setState((s: AppPayPalPaymentState) => ({ ...s, alert: undefined }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const successAlert = (details: { [key: string]: any }) => {
    setState((s: AppPayPalPaymentState) => ({
      ...s,
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Transaction completed!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          <small>
            *It may take a few minutes to process your payment with PayPal
          </small>
        </ReactBSAlert>
      ),
    }));
  };
  //   >
  //   <small>
  //     *It may take a few minutes to process your payment with PayPal
  //   </small>
  // </ReactBSAlert>

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const errorAlert = (err: any) => {
    setState((s: AppPayPalPaymentState) => ({
      ...s,
      alert: (
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Error"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="danger"
          btnSize=""
        >
          An error occurred during your transaction. Please try again.
        </ReactBSAlert>
      ),
    }));
  };

  return (
    <>
      {state.alert}
      <Row>
        <Col md="12">
          <AppSelect
            className="mb-3"
            options={prepaidOptions}
            value={selectedAmount}
            onChange={handleSelect}
          />
          {isCustom && (
            <AppInput
              className="mb-3"
              placeholder="Enter USD amount"
              value={customAmount}
              onChange={handleCustomAmount}
              label="Enter the amount you want to pay."
              focus={isCustom}
              type="number"
              min={custom.min}
              max={custom.max}
              step={custom.step}
              error={customError}
            />
          )}
          <PayPalButton
            options={{ clientId }}
            createOrder={(
              data: { [key: string]: any },
              actions: { [key: string]: any }
            ) =>
              actions.order.create({
                purchase_units: [
                  {
                    description: "SignKontrol",
                    reference_id: user.account_id_k,
                    amount: {
                      currency_code,
                      value: paypalAmount,
                    },
                  },
                ],
              })
            }
            onError={(err: any) => errorAlert(err)}
            onApprove={(
              data: { [key: string]: any },
              actions: { [key: string]: any }
            ) =>
              // Capture the funds from the transaction
              actions.order
                .capture()
                .then((details: { [key: string]: any }) =>
                  successAlert(details)
                )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="text-center">
          <small>
            *It may take a few minutes to process your payment with PayPal
          </small>
        </Col>
      </Row>
    </>
  );
};

export default AppPayPalPayment;
