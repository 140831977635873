import { createSelector } from "@reduxjs/toolkit";

import { DisplayGroup } from "validation/schemas/displayGroup";

import {
  createEntitySlice,
  loadEntities,
  saveEntity,
  //   openEntity,
  //   closeEntity,
  deleteEntity,
  EntityState,
  LoadEntitiesParameters,
  SaveEntityParameters,
} from "./entity";

export const idAttribute = "display_group_id_k";
export const defaultDisplayGroup = {
  display_group_name: "",
};

const url = "/display-groups";
const name = "display-groups";
const nameAttribute = "display_group_name";

const initialState: EntityState<DisplayGroup> = {
  synchronizing: false,
  list: [],
  idAttribute,
  nameAttribute,
  loading: false,
  data: {},
};

// Create Slice
const slice = createEntitySlice({ name, initialState, reducers: {} });

// Export Reducer and Actions
export default slice.reducer;
export const { actions } = slice;

// Action Creators
export const loadDisplayGroups = (params?: LoadEntitiesParameters["params"]) =>
  loadEntities({ url, params, slice });

export const saveDisplayGroup = (data: SaveEntityParameters["data"]) =>
  saveEntity({ url, idAttribute, data, slice });

export const deleteDisplayGroup = (id: string) =>
  deleteEntity({ url, id, slice });

export const getDisplayGroups = createSelector(
  (state: { [key: string]: any }) => state.entities.displayGroups,
  (displayGroups) => displayGroups
);

export const getDisplayGroupsList = createSelector(
  (state: { [key: string]: any }) => state.entities.displayGroups,
  (displayGroups) => {
    const { list } = displayGroups;
    if (!list || !Array.isArray(list)) return [];

    return list.map(
      ({
        display_group_id_k,
        display_group_name,
      }: {
        [key: string]: string;
      }) => ({ value: display_group_id_k, label: display_group_name })
    );
  }
);

export const getDefaultDisplayGroup = createSelector(
  (state: { [key: string]: any }) => state.entities.displayGroups,
  (displayGroups) => {
    const { list } = displayGroups;
    if (!list || !Array.isArray(list)) return;

    return list.find(({ is_default }) => is_default === true);
  }
);
