import React from "react";
import {
  FormGroup,
  Label,
  FormFeedback,
  Input,
  InputProps,
  FormText,
} from "reactstrap";

export interface AppArrayInputProps extends InputProps {
  label?: string;
  error?: string;
  help?: string;
}

const AppArrayInput: React.FC<AppArrayInputProps> = ({
  name,
  label,
  value,
  error,
  help,
  ...rest
}) => {
  const invalid = error !== undefined;
  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      <Input
        invalid={invalid}
        value={value}
        name={name}
        id={name}
        type="text"
        {...rest}
      />
      {error && <FormFeedback>{error}</FormFeedback>}
      {help && <FormText>{help}</FormText>}
    </FormGroup>
  );
};

AppArrayInput.defaultProps = {};

export default AppArrayInput;
